import react, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import Background from "../style/image/banner_image/cnr.png";
import {
  EmailIcon,
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  XIcon,
  FacebookIcon,
  LinkedinIcon,
} from "react-share";
const Contact = () => {
  const form = useRef();
  const [load, setLoad] = useState(false);
  const [sended, setSended] = useState(false);
  const [notSended, setNotSended] = useState(false);
  const sendEmail = (e) => {
    e.preventDefault();
    setLoad(true);
    emailjs
      .sendForm(
        "service_64gu89f",
        "template_k68b0aa",
        form.current,
        "Ux1e7-20W4Cp8MlfK"
      )
      .then(
        (result) => {
          setLoad(false);
          setSended(true);
          setNotSended(false);
          console.log(result.text);
          document.getElementById("myform").reset();
        },
        (error) => {
          setLoad(false);
          setSended(false);
          setNotSended(true);
          console.log(error.text);
        }
      );
  };
  const iconSize = 30;
  return (
    <div onLoad={()=>{window.scrollTo({top:0})}}>
      <div
        className="fadeIN opacity-0 w-full mx-auto bg-no-repeat xl:h-[500px] md:h-[380px] h-[160px] bg-center bg-cover md:mt-[-80px]"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="xl:h-[410px] md:h-[240px] h-[140px] ">
          <div className="mx-auto h-full">
            <h1 className="md:text-6xl text-3xl font-bold text-white text-center md:pt-48 py-10">
              Contact Us!
            </h1>
          </div>
        </div>
      </div>
      <div className="md:w-4/5 w-11/12 mx-auto bg-white rounded-b-lg pt-10 mb-10 ">
        <div className="flex md:flex-row flex-col justify-center md:space-x-4 md:space-y-0 space-y-2">
          <div className="w-11/12 md:w-60 bg-white border border-gray-200 rounded-lg shadow md:mx-0 mx-auto">
            <div className="flex md:flex-col flex-row items-center md:pb-10 pb-4 mx-1 mt-2">
            <div className="md:basis-full basis-2/6">

              <img
                className="md:w-24 md:h-24 w-16 h-16 mx-auto my-auto rounded-full shadow-lg"
                src={require("../style/image/researcher_profile/ilse_manet.jpeg")}
                alt="Bonnie image"
              />
            </div>
            <div className="flex flex-col md:basis-full basis-4/6">

              <h5 className="mb-1 md:text-xl text-lg font-medium text-gray-800 ">
                Ilse Manet
              </h5>
              <span className="md:text-lg text-base text-gray-800 ">
                Project Coordinator
              </span>
              <span className="md:text-lg text-base text-gray-800 ">
                ilse.manet@isof.cnr.it
              </span>
            </div>
            </div>
            <div className="flex flex-row w-full justify-center space-x-1 mx-auto mb-1">
              <a href="https://twitter.com/ilsemanet" target="_blank">
                <XIcon size={iconSize} round />
              </a>
              <a href='https://www.linkedin.com/in/ilse-manet-21708870/' target="_blank">
                <LinkedinIcon size={iconSize} round />
              </a>
            </div>
          </div>
          <div className="w-11/12 md:w-60 bg-white border border-gray-200 rounded-lg shadow  mx-auto">
            <div className="flex md:flex-col flex-row items-center md:pb-10 pb-4 mx-1 mt-2">
            <div className="md:basis-full basis-2/6">

              <img
                className="md:w-24 md:h-24 w-16 h-16 mx-auto my-auto rounded-full shadow-lg"
                src={require("../style/image/logo.png")}
                alt="Bonnie image"
              />
            </div>
            <div className="flex flex-col md:basis-full basis-4/6">

              <h5 className="mb-1 md:text-xl text-lg font-medium text-gray-800 ">
                To be defined
              </h5>
              <span className="md:text-lg text-base text-gray-800 ">
                Project Manager 
              </span>
              <span className="md:text-lg text-base text-gray-800 ">
                bicyclos23@gmail.com
              </span>
            </div>
            </div>
            <div className="flex flex-row w-full justify-center space-x-1 mx-auto mb-1">
              <a href="https://twitter.com/Bicyclos_eu" target="_blank">
                <XIcon size={iconSize} round />
              </a>
              <a href="https://www.linkedin.com/in/bicyclos-heurope-b2b1232a3/" target="_blank">
                <LinkedinIcon size={iconSize} round />
              </a>
            </div>
          </div>
        </div>
        <div className="w-4/5 mx-auto">
          <form ref={form} onSubmit={sendEmail} id="myform">
            <div className="space-y-12">
              <div className="border-b-2 border-gray-300 md:pb-12 pb-6">
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label className="block md:text-lg text-base font-medium leading-6 text-gray-800">
                      First name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="from_name"
                        id="first-name"
                        autoComplete="given-name"
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-base sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block md:text-lg text-base font-medium leading-6 text-gray-800"
                    >
                      Last name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="from_surname"
                        id="last-name"
                        autoComplete="family-name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-base sm:leading-6"
                        required
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-4">
                    <label
                      htmlFor="email"
                      className="block md:text-lg text-base font-medium leading-6 text-gray-800"
                    >
                      Email address
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name="user_email"
                        type="email"
                        autoComplete="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-base sm:leading-6"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="about"
                      className="block md:text-lg text-base font-medium leading-6 text-gray-800"
                    >
                      Write your message here:
                    </label>
                    <div className="mt-2">
                      <textarea
                        id="about"
                        name="message"
                        rows={5}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-base sm:leading-6"
                        defaultValue={""}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 flex items-center justify-end gap-x-6">
              {load ? (
                <div
                  role="status"
                  className="flex flex-row border-[#80968A] border-2 rounded-lg p-2"
                >
                  <svg
                    aria-hidden="true"
                    className="w-8 h-8 text-gray-200 animate-spin  fill-[#80968A]"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <p className="md:text-lg text-base ml-2">sending email..</p>
                </div>
              ) : (
                <button
                  type="submit"
                  value="Send"
                  className="bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg  text-white font-bold py-1 px-4 rounded-lg l-0 mb-5"
                >
                  Send
                </button>
              )}
            </div>
          </form>
          {sended && (
            <div
              className="bg-green-100 border-t border-b border-green-800 text-green-700 px-4 py-3 md:mb-14 mt-5"
              role="alert"
            >
              <p className="font-bold md:text-xl text-lg">OK</p>
              <p className="md:text-lg text-base ml-2">
                Your email has been sent successfully! Thanks for your feedback
              </p>
            </div>
          )}
          {notSended && (
            <div
              className="bg-red-100 border-l-4 border-red-800 text-red-700 p-4  md:mb-14 mt-5"
              role="alert"
            >
              <p className="font-bold md:text-xl text-lg">ERROR</p>
              <p className="md:text-lg text-base">
                There was an error sending the email, please try again. if the
                problem persists contact the coordinator directly! we apologize
                for the inconvenience
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Contact;
