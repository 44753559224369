import react, { useState, useEffect, useContext } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useHistory, NavLink } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { getAuth, signOut } from "firebase/auth";
const Footer = () => {
  const logout = () => {
    const auth = getAuth();
    signOut(auth)
    .then(() => {
      console.log('entrato')
      dispatch({ type: "LOGOUT", payload: null });
    })
    .catch((error) => {
      // An error happened.
    });
  };
  const {dispatch} = useContext(AuthContext)
  const { currentUser } = useContext(AuthContext);
  return (
    <>
      <footer className="relative bottom-0 w-full px-2 bg-[#C5D4D0] ">
        <div className="mx-auto w-full  py-6 lg:py-8">
          <div className="flex md:flex-row flex-col justify-between">
            <div className="mb-6 md:mb-0 flex-none mx-auto">
              <img
                src={require("./style/image/partner_image/horizon-europe.jpg")}
                className="h-32 me-3 rounded-lg"
                alt="FlowBite Logo"
              />
            </div>
            <div className=" grow md:my-auto">
              <p className="md:w-8/12 w-full md:text-xl text-base text-center mx-auto md:my-0 my-5 font-semibold text-[#68736b]">
                Bicyclos is a project funded by the European Union’s Horizon
                Europe Research and Innovation Programme and coordinated by the
                National Research Council in Italy.
              </p>
            </div>
            <div className=" md:mr-10 flex-none">
              <div>
                <h2 className="mb-6 text-sm font-semibold text-[#68736b] uppercase ">
                  Legal
                </h2>
                <ul className="text-gray-500  font-medium">
                  <li className="mb-4">
                    <NavLink
                      to="/privacy"
                      className="hover:underline text-[#68736b]"
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li className="mt-4">
                    <NavLink
                      href="#"
                      className="hover:underline text-[#68736b]"
                      to="/contact"
                    >
                      contact us
                    </NavLink>
                  </li>
                  {currentUser != null ? (
                    <li
                      className="mt-3 hover:underline text-[#68736b] cursor-pointer"
                      onClick={logout}
                    >
                      logout
                    </li>
                  ) : (
                    <li className="mt-4">
                      <NavLink
                        href="#"
                        className="hover:underline text-[#68736b]"
                        to="/login"
                      >
                        login
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <hr className="my-6 border-gray-200 mx-auto  lg:my-8" />
          <div className="flex sm:items-center sm:justify-center  sm:flex-row flex-col-reverse">
            <span className="md:text-lg text-base mx-auto text-[#68736b] sm:text-center sm:mt-0 mt-2">
              <a href="#" className="hover:underline text-[#68736b]">
                ©jonas_micocci
              </a>
              . All Rights Reserved.
            </span>
            <div className="flex mt-4 sm:justify-center justify-start sm:mt-0">
              <a
                href="https://twitter.com/Bicyclos_eu"
                className="text-gray-500 hover:text-gray-900 "
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 512 512"
                  fill="currentColor"
                >
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
                <span className="sr-only">Twitter page</span>
              </a>
              <a
                href="https://www.linkedin.com/in/bicyclos-heurope-b2b1232a3/"
                className="text-gray-500 hover:text-gray-900  ms-5"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-4 h-4"
                  viewBox="0 0 448 512"
                  fill="currentColor"
                >
                  <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
                </svg>

                <span className="sr-only">Linkedin </span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
