import react from "react";
import { InView } from "react-intersection-observer";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useContext, useState, useEffect, Fragment, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { research } from "../data/researcher";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
const Information = () => {
  const [publicInfo, setPublicInfo] = useState([]);
  const [NotPublicInfo, setNotPublicInfo] = useState([]);
  const cancelButtonRef = useRef(null);
  //const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [update, setUpdate] = useState(false);
  const [form, setForm] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const [id, setId] = useState(null);
  const [updatePublicInfo, setUpdatePublicInfo] = useState({
    id: "",
    author: "",
    website: "",
    date: "",
    type: "",
    title: "",
    description: "",
    file: "",
    repository: "",
  });
  function translateNews(i) {
    var elem = document.getElementsByClassName("news");
    if (!elem[i].classList.contains("news-translate")) {
      elem[i].classList.toggle("news-translate");
    }
  }
  function visibleNews(i) {
    var elem = document.getElementsByClassName("news-visible");
    if (!elem[i].classList.contains("active-news")) {
      elem[i].classList.toggle("active-news");
    }
  }
  const filterData = ({ target }) => {
    if (target.value == "") {
      setPublicInfo(NotPublicInfo);
    } else {
      let tmp;
      tmp = NotPublicInfo.filter((i) => {
        return (
          i.author.toLowerCase().includes(target.value.toLowerCase()) ||
          i.type.toLowerCase().includes(target.value.toLowerCase()) ||
          i.date.includes(target.value.toLowerCase()) ||
          i.title.toLowerCase().includes(target.value.toLowerCase())
        );
      });

      setPublicInfo(tmp);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(
          collection(db, "public_information")
        );

        querySnapshot.forEach((doc) => {
          setLoading(false);
          list.push(doc.data());
        });
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setPublicInfo(
        list
          .sort((a, b) => {
            let dateA = new Date(a.date);
            let dateB = new Date(b.date);
            return dateA - dateB;
          })
          .reverse()
      );
      setNotPublicInfo(
        list
          .sort((a, b) => {
            let dateA = new Date(a.date);
            let dateB = new Date(b.date);
            return dateA - dateB;
          })
          .reverse()
      );
    };
    fetchData();
  }, []);
  const uploadPublicInfo = async (e) => {
    e.preventDefault();
    if (update) {
      updateAPI(e);
      return;
    } else {
      e.preventDefault();
      console.log(e.target.file.files[0]);
      setSubmitLoading(true);
      if (e.target.file.files[0] != undefined) {
        const storageRef = ref(
          storage,
          "public_information/" + e.target.idPublic.value
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          e.target.file.files[0]
        );
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            alert(error.message, error.code);
            console.log(error.message, error.code);
          },
          () => {
            const downl = getDownloadURL(uploadTask.snapshot.ref).then(
              (downloadURL) => {
                console.log("File available at", downloadURL);
                uploadData(downloadURL);
              }
            );
          }
        );
      } else {
        uploadData("");
      }
      async function uploadData(down) {
        await setDoc(doc(db, "public_information", e.target.idPublic.value), {
          id: e.target.idPublic.value,
          author: e.target.authors.value,
          website: e.target.website.value,
          date: e.target.date.value,
          type: e.target.type.value,
          title: e.target.title.value,
          description: e.target.description.value,
          file: down,
          repository: e.target.repository.value,
        });
        setSubmitted(true);
        setSubmitLoading(false);
        location.reload();
      }
    }
  };
  async function deletePublicInfo(e) {
    await deleteDoc(doc(db, "public_information", e.id));
    if (e.file != "") {
      deleteFile(e);
    } else {
      location.reload();
    }
  }
  function deleteFile(e) {
    let imageRef = "public_information/" + e.id;
    const desertRef = ref(storage, imageRef);
    deleteObject(desertRef)
      .then(async () => {
        console.log("eliminato");
        location.reload();
      })
      .catch((error) => {
        console.log("non eliminato");
      });
  }
  async function updateFile(downloadURL, e) {
    const newsRef = doc(db, "public_information", e.target.idPublic.value);
    await updateDoc(newsRef, {
      file: downloadURL,
    });
    setSubmitted(true);
    setSubmitLoading(false);
    location.reload();
  }
  async function updateAPI(e) {
    setSubmitLoading(true)
    const newsRef = doc(db, "public_information", e.target.idPublic.value);
    await updateDoc(newsRef, {
      id: e.target.idPublic.value,
      author: e.target.authors.value,
      website: e.target.website.value,
      date: e.target.date.value,
      type: e.target.type.value,
      title: e.target.title.value,
      description: e.target.description.value,
      repository: e.target.repository.value,
    });
    if (e.target.delete!=undefined && e.target.delete.checked) {
      console.log("entrato");
      let imageRef = "public_information/" + e.target.idPublic.value;
      const desertRef = ref(storage, imageRef);
      deleteObject(desertRef)
        .then(async () => {
          console.log("eliminato");
          const newsRef = doc(
            db,
            "public_information",
            e.target.idPublic.value
          );
          await updateDoc(newsRef, {
            file: "",
          });
          location.reload();
        })
        .catch((error) => {
          console.log("non eliminato");
        });
    }
    if (e.target.file.files[0] != undefined) {
      const storageRef = ref(
        storage,
        "public_information/" + e.target.idPublic.value
      );
      const uploadTask = uploadBytesResumable(
        storageRef,
        e.target.file.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          alert(error.message, error.code);
          console.log(error.message, error.code);
        },
        () => {
          const downl = getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => {
              console.log("File available at", downloadURL);
              updateFile(downloadURL, e);
            }
          );
        }
      );
    } else {
      location.reload();
      setSubmitted(true);
      setSubmitLoading(false);
    }
  }
  return (
    <>
      <Transition.Root show={form} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={()=>{setForm(false), setUpdate(false), setUpdatePublicInfo({
            id: "",
            author: "",
            website: "", 
            date: "",
            type: "",
            title: "",
            description: "",
            file: "",
            repository: "",
          })}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                  <XMarkIcon
                    className="absolute h-8 w-8 end-5 top-5 cursor-pointer "
                    onClick={() => {
                      setForm(false);
                      setSubmitted(false);
                      setUpdate(false);
                      setUpdatePublicInfo({
                        id: "",
                        author: "",
                        website: "", 
                        date: "",
                        type: "",
                        title: "",
                        description: "",
                        file: "",
                        repository: "",
                      });
                    }}
                    fill="#1f2937"
                  />
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center mx-auto sm:mt-0 sm:text-left w-4/5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {update ? (
                            <div>update public information</div>
                          ) : (
                            <div>upload new public information</div>
                          )}
                        </Dialog.Title>
                        <div className="mt-2">
                          <form onSubmit={uploadPublicInfo}>
                            <div className="relative z-0 w-full mb-5 group">
                              <input
                                type="text"
                                name="idPublic"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                placeholder=" "
                                disabled
                                defaultValue={id}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                ID
                              </label>
                            </div>
                            {/*event title, event type*/}
                            <div className="relative z-0 w-full mb-5 group">
                              <div className="w-full">
                                <input
                                  type="text"
                                  name="title"
                                  id="floating_repeat_password"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                  placeholder=" "
                                  defaultValue={updatePublicInfo.title}
                                />
                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                  public information title
                                </label>
                              </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                              <div className="w-full">
                                <input
                                  type="text"
                                  name="authors"
                                  id="floating_repeat_password"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                  placeholder=" "
                                  defaultValue={updatePublicInfo.author}
                                />
                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                  authors
                                </label>
                              </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group flex flex-row space-x-4">
                              <div className="w-full">
                                <input
                                  type="text"
                                  name="type"
                                  id="floating_repeat_password"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                  placeholder=" "
                                  defaultValue={updatePublicInfo.type}
                                />
                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                  type of public information
                                </label>
                              </div>
                              <div className="w-full">
                                <input
                                  type="text"
                                  name="website"
                                  id="floating_repeat_password"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                  placeholder=" "
                                  defaultValue={updatePublicInfo.website}
                                />
                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                  website
                                </label>
                              </div>
                            </div>
                            <div className="relative z-0 w-full mb-5 group flex flex-row space-x-4">
                              <div className="w-full">
                                <input
                                  type="text"
                                  name="date"
                                  id="floating_repeat_password"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                  placeholder=" "
                                  defaultValue={updatePublicInfo.date}
                                />
                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                  date of publication
                                </label>
                              </div>
                              <div className="w-full">
                                <input
                                  type="text"
                                  name="repository"
                                  id="floating_repeat_password"
                                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600  focus:outline-none focus:ring-0  peer"
                                  placeholder=" "
                                  defaultValue={updatePublicInfo.repository}
                                />
                                <label className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-focus:dark:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                  link to repository
                                </label>
                              </div>
                            </div>
                            {/*text area*/}
                            <div className="relative z-0 w-full mb-5 group">
                              <textarea
                                id="about"
                                name="description"
                                placeholder="insert news text here"
                                rows={5}
                                className=" w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-sm sm:leading-6"
                                defaultValue={updatePublicInfo.description}
                              />
                            </div>
                            {update && updatePublicInfo.file!="" && (
                              <div>
                                <input
                                  type="radio"
                                  name="delete"
                                  value="delete"
                                />
                                <label>delete download</label>
                              </div>
                            )}

                            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                              upload file
                            </label>
                            <input
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                              type="file"
                              name="file"
                            />

                            {submitLoading ? (
                              <div
                                role="status"
                                className="flex flex-row border-[#80968A] border-2 rounded-lg p-2"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="w-8 h-8 text-gray-200 animate-spin  fill-[#80968A]"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <p className="md:text-lg text-base ml-2">
                                  uploading public information...
                                </p>
                              </div>
                            ) : (
                              <button
                                type="submit"
                                className="mt-5 bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg  text-white font-bold py-1 px-4 rounded-lg l-0 mb-5"
                              >
                                {update
                                  ? "save changes"
                                  : "Upload public information"}
                              </button>
                            )}
                            {submitted && (
                              <div
                                className="bg-green-100 border-t border-b border-green-800 text-green-700 px-4 py-3 md:mb-14 mt-5"
                                role="alert"
                              >
                                <p className="md:text-lg text-base ml-2">
                                  {update
                                    ? "changes saved successfully"
                                    : "public information uploaded successfully"}
                                </p>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="min-[2000px]:min-h-[700px] min-h-[500px] bg-white w-11/12 mx-auto mb-10 rounded-b-lg"
        onLoad={()=>{if(firstLoad){window.scroll(0, 0); setFirstLoad(false)}}}
      >
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleNews(0), 4000);
              }
            }}
            className="news-visible opacity-0 flex flex-row mx-auto items-baseline flex-wrap md:flex-nowrap justify-center md:justify-between border-b border-gray-200 pb-10 pt-10"
          >
            <h1 className="text-5xl font-bold my-auto flex-none md:flex-auto w-full md:w-fit text-center md:text-left tracking-tight text-[#80968A] mb-5 md:mb-0">
              Public Informations
            </h1>
            <div className="relative left-0">
              <svg
                className="w-6 h-6 text-gray-700 absolute top-3 left-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
              <input
                type="search"
                placeholder="Search"
                id="search"
                onChange={filterData}
                className="border-b w-full text-lg border-gray-200 py-3 pl-7 bg-transparent"
              />
            </div>
          </InView>
          {!loading ? (
            <div className="lg:col-span-3 pb-20">
              <div className="md:w-4/6 w-5/6 m-auto md:my-20 my-10">
                {currentUser != null && (
                  <button
                    className=" bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A]  md:text-lg  text-white font-bold py-1 px-4 mx-auto rounded-lg mb-5 mt-5 "
                    onClick={() => {
                      setForm(true);
                      setId(NotPublicInfo.length + 1);
                    }}
                  >
                    {" "}
                    + Add new public information
                  </button>
                )}

                {publicInfo.length != 0 ? (
                  <ol className="relative mt-3 border-s-2 border-[#80968A]  border-opacity-60">
                    {publicInfo.map((e, i) => {
                      var date = new Date(e.date);
                      const month = [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ];

                      return (
                        <li
                          className="my-10 ms-6 md:h-auto h-auto pb-4"
                          key={i}
                        >
                          <span className="absolute flex items-center justify-center w-8 h-8 bg-[#C5D4D0] rounded-full -start-4 shadow-[0_-5px_rgb(255,255,255)]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.8"
                              stroke="currentColor"
                              className="w-5 h-5 m-auto text-[#68736B] "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                              />
                            </svg>
                          </span>
                          <InView
                            as="div"
                            onChange={(inView, entry) => {
                              if (inView) {
                                setTimeout(translateNews(i), 4000);
                              }
                            }}
                            className="news opacity-0"
                          >
                            <div className="flex flex-row">
                              <p className="block my-2 md:text-lg text-base font-normal leading-none text-gray-800 ">
                                {e.type}
                              </p>
                              
                              {currentUser != null && (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="-2 -2 28 28"
                                    strokeWidth="1.5"
                                    stroke="white"
                                    className="w-6 h-6 rounded-full bg-green-600 ml-3 my-auto cursor-pointer"
                                    onClick={() => {
                                      setUpdate(true);
                                      setForm(true);
                                      setId(e.id);
                                      setUpdatePublicInfo(e);
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                    />
                                  </svg>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="-2 -2 28 28"
                                    strokeWidth="1.5"
                                    stroke="white"
                                    className="w-6 h-6 rounded-full bg-red-700 ml-1 my-auto cursor-pointer"
                                    onClick={() => {
                                      deletePublicInfo(e);
                                    }}
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </>
                              )}
                            </div>
                            <p className="block my-2 md:text-2xl text-lg font-normal leading-none text-gray-800 ">
                              {e.title}
                            </p>
                            <p className="block my-2 md:text-lg text-base font-normal leading-none text-gray-800 ">
                              {e.author}
                            </p>
                            <p className="block my-2 md:text-lg text-base font-normal leading-none text-gray-800 ">
                              {e.date}
                            </p>
                            <p className="mb-1  md:text-lg text-base font-normal    text-gray-500 ">
                              {e.description}
                            </p>
                            {e.file == "" ? (
                              <a
                                href={e.website}
                                target="_blank"
                                className="inline-flex mt-3 items-center px-2 py-1 md:text-lg text-base font-medium hover:bg-[#C5D4D0] hover:text-[#80968A] bg-[#80968A] text-white  rounded-lg"
                              >
                                Website
                              </a>
                            ) : (
                              <>
                                <Link
                                  to={e.file}
                                  target="_blank"
                                  download
                                  className="inline-flex mt-3 items-center px-2 py-1 md:text-lg text-base font-medium hover:bg-[#C5D4D0] hover:text-[#80968A] bg-[#80968A] text-white  rounded-lg"
                                >
                                  Download
                                </Link>
                              </>
                            )}
                          </InView>
                        </li>
                      );
                    })}
                  </ol>
                ) : (
                  <div
                    class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
                    role="alert"
                  >
                    <p class="font-bold">We are sorry!</p>
                    <p>No news found</p>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="wrapper_load mt-10">
              <div className="circle_load"></div>
              <div className="circle_load"></div>
              <div className="circle_load"></div>
              <div className="shadow_load"></div>
              <div className="shadow_load"></div>
              <div className="shadow_load"></div>
              <span>Loading</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Information;
