import react, { useEffect, useState, useRef } from "react";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useHistory, NavLink } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/", current: true },
  {
    name: "Project",
    href: ["/project/objective", "/project/activities", "/project/work_plan"],
    current: true,
    navigation: ["Objective", "Activities", "Work Plan"],
  },
  { name: "Partners", href: "/partners", current: false },
  {
    name: "Media Room",
    href: ["/Information", "/Data"],
    current: false,
    navigation: ["Public informations", "Data and publications"],
  },
  {
    name: "News Room",
    href: ["/news", "/events"],
    current: false,
    navigation: ["News", "Events"],
  },
  { name: "Contacts", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const style = "py-1 pl-2 md:px-2 text-base font-bold lg:text-lg w-auto ";
export default function Navbar() {
  const navig = useNavigate();
  const [open, setOpen] = useState(false);
  const [prevNav, setPrevNav] = useState(null);
  function dropDown(name) {
    var drop = document.getElementById(name);
    drop.classList.toggle("hidden");
  }

  function dropDownresp(name, check) {
    if (prevNav == null) {
      setPrevNav(name);
    } else {
      if (name == prevNav) {
        setPrevNav(null);
      } else {
        document.getElementsByName(prevNav)[0].classList.add("hidden");
        setPrevNav(name);
      }
    }
    var drop = document.getElementsByName(name);
    drop[0].classList.toggle("hidden");
  }
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const imageRef1 = useRef();
  const imageRef2 = useRef();
  const location = useLocation();
  useEffect(() => {
    const items = document.getElementsByClassName("item");
    let nav = document.getElementById("navbar");
    if (screen.width > 768) {
      if (
        location.pathname != "/" &&
        location.pathname != "/events" &&
        location.pathname != "/contact"
      ) {
        if (
          document.getElementsByClassName("imageRef2")[0].classList != undefined
        ) {
          document
            .getElementsByClassName("imageRef2")[0]
            .classList.remove("md:hidden");
          document
            .getElementsByClassName("imageRef1")[0]
            .classList.remove("md:block");
        }
        nav.style.animation = "navfadein 0s ease-in forwards";
        for (let i = 0; i < items.length; i++) {
          items[i].style.color = "rgb(104,115,107)";
          items[i].classList.remove("hover:border-[#c5d4d0]");
          items[i].classList.add("hover:border-[#68736B]");
          items[i].classList.remove("border-b-[#c5d4d0]");
          if (items[i].classList.contains("active")) {
            if (!items[i].classList.contains("border-b-[#68736B]")) {
              items[i].classList.add("border-b-[#68736B]");
            }
          } else {
            if (items[i].classList.contains("border-b-[#68736B]")) {
              items[i].classList.remove("border-b-[#68736B]");
            }
          }
        }
      } else {
        let nav = document.getElementById("navbar");
        nav.style.animation = "navfadeout 0s ease-in forwards";
        if (
          document.getElementsByClassName("imageRef2")[0].classList != undefined
        ) {
          document
            .getElementsByClassName("imageRef2")[0]
            .classList.add("md:hidden");
          document
            .getElementsByClassName("imageRef1")[0]
            .classList.add("md:block");
        }
        for (let i = 0; i < items.length; i++) {
          items[i].style.color = "rgb(197,212,208)";
          items[i].classList.add("hover:border-[#c5d4d0]");
          items[i].classList.remove("hover:border-[#68736B]");
          items[i].classList.remove("border-b-[#68736B]");
          if (items[i].classList.contains("active")) {
            if (!items[i].classList.contains("border-b-[#c5d4d0]")) {
              items[i].classList.add("border-b-[#c5d4d0]");
            }
          } else {
            if (items[i].classList.contains("border-b-[#c5d4d0]")) {
              items[i].classList.remove("border-b-[#c5d4d0]");
            }
          }
        }
      }
    }
  }, [location.pathname]);
  window.onscroll = () => {
    scrollDetect(location.pathname);
  };
  function scrollDetect(loca) {
    const items = document.getElementsByClassName("item");
    if (screen.width > 768) {
      if (loca == "/" || loca == "/events" || loca == "/contact") {
        if (window.scrollY > 100) {
          let nav = document.getElementById("navbar");

          if (
            document.getElementsByClassName("imageRef2")[0].classList !=
            undefined
          ) {
            document
              .getElementsByClassName("imageRef2")[0]
              .classList.remove("md:hidden");
            document
              .getElementsByClassName("imageRef1")[0]
              .classList.remove("md:block");
          }
          nav.style.animation = "navfadein 0.2s ease-in forwards";
          for (let i = 0; i < items.length; i++) {
            items[i].style.color = "rgb(104,115,107)";
            items[i].classList.remove("hover:border-[#c5d4d0]");
            items[i].classList.add("hover:border-[#68736B]");
            items[i].classList.remove("border-b-[#c5d4d0]");
            if (items[i].classList.contains("active")) {
              if (!items[i].classList.contains("border-b-[#68736B]")) {
                items[i].classList.add("border-b-[#68736B]");
              }
            } else {
              if (items[i].classList.contains("border-b-[#68736B]")) {
                items[i].classList.remove("border-b-[#68736B]");
              }
            }
          }
        } else {
          let nav = document.getElementById("navbar");
          nav.style.animation = "navfadeout 0.25s ease-in forwards";
          if (
            document.getElementsByClassName("imageRef2")[0].classList !=
            undefined
          ) {
            document
              .getElementsByClassName("imageRef2")[0]
              .classList.add("md:hidden");
            document
              .getElementsByClassName("imageRef1")[0]
              .classList.add("md:block");
          }
          for (let i = 0; i < items.length; i++) {
            items[i].style.color = "rgb(197,212,208)";
            items[i].classList.add("hover:border-[#c5d4d0]");
            items[i].classList.remove("hover:border-[#68736B]");
            items[i].classList.remove("border-b-[#68736B]");
            if (items[i].classList.contains("active")) {
              if (!items[i].classList.contains("border-b-[#c5d4d0]")) {
                items[i].classList.add("border-b-[#c5d4d0]");
              }
            } else {
              if (items[i].classList.contains("border-b-[#c5d4d0]")) {
                items[i].classList.remove("border-b-[#c5d4d0]");
              }
            }
          }
        }
      } else {
      }
    }
  }
  return (
    <>
      <Disclosure
        as="nav"
        id="navbar"
        className="md:bg-[rgb(197,212,208,0)] bg-[#c5d4d0] sticky top-0 w-full opacity-100 z-10 navbar"
      >
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl  sm:px-6 lg:px-8" key={open}>
              <div className="relative flex lg:h-20 h:16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-[rgb(104,115,107)]  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="lg:h-20 h:16 flex flex-1 items-center justify-center sm:items-stretch sm:justify-center">
                  <div className="flex h-14 flex-shrink-0 items-start ">
                    <img
                      ref={imageRef1}
                      className="imageRef1 h-10  w-auto mt-2 lg:h-14 lg:mt-3 z-10 md:block hidden"
                      src={require("./style/image/logo_transparent.png")}
                      alt="Your Company"
                    />
                    <img
                      ref={imageRef2}
                      className="imageRef2 h-10  w-auto mt-2 lg:h-14 lg:mt-3 z-10 md:hidden block"
                      src={require("./style/image/logo.png")}
                      alt="Your Company"
                    />
                  </div>
                  <div className="hidden grow  sm:block items-center">
                    <div className="flex space-x-4 h-full items-center justify-end navlink">
                      {navigation.map((item, i) => {
                        if (Array.isArray(item.href)) {
                          return (
                            <div
                              className="relative  cursor-pointer"
                              onMouseEnter={() => {
                                dropDown(item.name, false);
                              }}
                              onMouseLeave={() => {
                                dropDown(item.name, false);
                              }}
                              data-te-dropdown-ref
                              key={i}
                            >
                              <a
                                className="item py-2 text-base font-bold lg:text-lg text-[#c5d4d0] hover:border-b-2  hover:border-[#c5d4d0] flex items-center xl:px-6 px-2 pb-2 pt-2.5 "
                                type="button"
                                id="dropdownMenuButton2"
                                data-te-dropdown-toggle-ref
                                aria-expanded="false"
                              >
                                {item.name}
                                <span className="ml-2 w-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="h-5 w-5"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              </a>
                              <ul
                                className="absolute  z-[1000] float-left m-0 hidden w-full list-none overflow-hidden rounded-b-lg border-none bg-[#C5D4D0] bg-clip-padding text-left text-base  text-[#68736B] hover:border-b-2  hover:border-[#68736B] shadow-lg  [&[data-te-dropdown-show]]:block"
                                aria-labelledby="dropdownMenuButton2"
                                id={item.name}
                                data-te-dropdown-menu-ref
                              >
                                {item.navigation.map((e, i) => {
                                  return (
                                    <li className="" key={i}>
                                      <NavLink
                                        to={item.href[i]}
                                        className=" pl-2 md:text-lg text-[#68736B] hover:border-b-2 font-bold hover:border-[#68736B] block w-full  py-2  text-sm  "
                                        data-te-dropdown-item-ref
                                      >
                                        {e}
                                      </NavLink>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        } else {
                          return (
                            <NavLink
                              to={item.href}
                              key={item.name}
                              href={item.href}
                              className={({ isActive }) =>
                                isActive
                                  ? ` item active ${style}  `
                                  : ` item text-[rgb(197,212,208)] hover:border-b-2  hover:border-[#c5d4d0]  ${style} nav`
                              }
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </NavLink>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Disclosure.Panel className="sm:hidden boxNav">
              <div className="space-y-1 pb-3 pt-2">
                {navigation.map((item, i) => {
                  if (Array.isArray(item.href)) {
                    return (
                      <div
                        className="relative pl-2 py-1"
                        data-te-dropdown-ref
                        key={i}
                      >
                        <a
                          className="text-base font-bold md:text-lg text-[#68736B] flex items-center  transition duration-200  hover:ease-in-out  motion-reduce:transition-none   "
                          type="button"
                          id="dropdownMenuButton2"
                          data-te-dropdown-toggle-ref
                          aria-expanded="false"
                          onClick={() => {
                            dropDownresp(item.name, true);
                          }}
                        >
                          {item.name}
                          <span className="ml-2 w-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              className="h-5 w-5"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </span>
                        </a>
                        <ul
                          className="absolute  z-[1000] float-left shadow-2xl m-0 hidden md:w-full w-2/5 list-none overflow-hidden rounded-lg border-none bg-[#C5D4D0] bg-clip-padding text-left text-base  text-[#68736B] hover:border-b-2  hover:border-[#68736B]  [&[data-te-dropdown-show]]:block mt-3"
                          aria-labelledby="dropdownMenuButton2"
                          name={item.name}
                          data-te-dropdown-menu-ref
                        >
                          {item.navigation.map((e, i) => {
                            return (
                              <li className="" key={i}>
                                <Disclosure.Button
                                  as="button"
                                  onClick={() => {
                                    navig(item.href[i]);
                                  }}
                                  data-te-dropdown-item-ref
                                  className="md:text-lg text-left text-[#68736B] block w-5/6  whitespace-nowrap bg-transparent px-4 py-2 text-sm font-bold   active:no-underline disabled:pointer-events-none disabled:bg-transparent "
                                >
                                  {e}
                                </Disclosure.Button>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  } else {
                    return (
                      <Disclosure.Button
                        key={item.name}
                        as="button"
                        onClick={() => {
                          navig(item.href);
                        }}
                        href={item.href}
                        className={({ isActive }) =>
                          isActive
                            ? ` active ${style} w-1/5  `
                            : ` block text-[#68736B] hover:border-b-2  hover:border-[#68736B] w-1/5 ${style}`
                        }
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    );
                  }
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
