import { Fragment, useEffect, useRef, useState, useContext } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import Background from "../style/image/banner_image/banner_event.jpeg";
import { InView } from "react-intersection-observer";
import { AuthContext } from "../../context/AuthContext";
import { research } from "../data/researcher";
import { Link } from "react-router-dom";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
const Events = () => {
  const [open, setOpen] = useState(false);
  const [addCalendar, setAddCalendar] = useState();
  const [scrollY, setScrollY] = useState(false);
  const [event, setEvent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [nextEvents, setNextEvents] = useState();
  const [pastEvents, setPastEvents] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "events"));

        querySnapshot.forEach((doc) => {
          setLoading(false);
          list.push(doc.data());
        });
        setEvent(
          list
            .sort((a, b) => {
              let dateA = new Date(a.startDate);
              let dateB = new Date(b.startDate);
              return dateA - dateB;
            })
            .reverse()
        );
        setPastEvents(
          list.filter((e) => {
            let tmp = new Date();
            let dateA = new Date(e.startDate);
            return dateA < tmp;
          })
        );
        setNextEvents(
          list
            .filter((e) => {
              let tmp = new Date();
              let dateA = new Date(e.startDate);
              return dateA >= tmp;
            })
            .reverse()
        );
        let tmp_list = list
          .filter((e) => {
            let tmp = new Date();
            let dateA = new Date(e.startDate);
            return dateA >= tmp;
          })
          .reverse();
        setMainEvent(tmp_list[0]);
        setNextDateStart(new Date(tmp_list[0].startDate));
        setNextDateEnd(new Date(tmp_list[0].endDate));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    if (window.screen.width < 768) {
      setAddCalendar("6");
    } else {
      setAddCalendar("10");
    }
  }, [window.screen.width]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [eventView, setEventView] = useState([]);
  const [mainEvent, setMainEvent] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const cancelButtonRef = useRef(null);
  const [nextDateStart, setNextDateStart] = useState();
  const [nextDateEnd, setNextDateEnd] = useState();
  const currentDate = new Date();
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [submitted, setSubmitted] = useState(false);
  const [update, setUpdate] = useState(false);
  const [form, setForm] = useState(false);
  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  const [updateEvent, setUpdateEvent] = useState({
    id: "",
    title: "",
    type: "",
    location: "",
    startDate: "",
    endDate: "",
    description: "",
    image: "",
    website: "",
    place: "",
    timeZone: "",
    to_be_decided: "",
    organizer: "",
    file: "",
  });
  const uploadEvent = async (e) => {
    e.preventDefault();
    if (update) {
      e.preventDefault();
      updateAPI(e);
      return;
    } else {
      e.preventDefault();
      console.log(e.target.file.files[0]);
      setSubmitLoading(true);
      const storageRef = ref(storage, "events_image/" + e.target.idevent.value);
      const uploadTask = uploadBytesResumable(
        storageRef,
        e.target.file.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          alert(error.message, error.code);
          console.log(error.message, error.code);
        },
        () => {
          const downl = getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => {
              console.log("File available at", downloadURL);
              //if controlla se c'e un file allegato e nel caso lo carica, secondo caricamento annidato
              if (e.target.document.files[0] != undefined) {
                const storageRef = ref(
                  storage,
                  "events_document/" + e.target.idevent.value
                );
                const uploadTask = uploadBytesResumable(
                  storageRef,
                  e.target.document.files[0]
                );
                uploadTask.on(
                  "state_changed",
                  (snapshot) => {
                    const progress =
                      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log("Upload is " + progress + "% done");
                    switch (snapshot.state) {
                      case "paused":
                        console.log("Upload is paused");
                        break;
                      case "running":
                        console.log("Upload is running");
                        break;
                    }
                  },
                  (error) => {
                    alert(error.message, error.code);
                    console.log(error.message, error.code);
                  },
                  () => {
                    const downl = getDownloadURL(uploadTask.snapshot.ref).then(
                      (download) => {
                        console.log("File available at", download);
                        uploadData(downloadURL, download);
                      }
                    );
                  }
                );
              } else {
                uploadData(downloadURL, "");
              }
            }
          );
        }
      );
      async function uploadData(img, document) {
        await setDoc(doc(db, "events", e.target.idevent.value), {
          id: e.target.idevent.value,
          title: e.target.eventtitle.value,
          type: e.target.type.value,
          location: e.target.location.value,
          startDate: e.target.startdate.value,
          endDate: e.target.enddate.value,
          description: e.target.description.value,
          image: img,
          website: e.target.website.value,
          place: e.target.place.value,
          timeZone: e.target.timezone.value,
          to_be_decided: e.target.to_be_decided.value,
          organizer: e.target.organizer.value,
          file: document,
        });
        setSubmitted(true);
        setSubmitLoading(false);
        window.location.reload();
      }
    }
  };
  function showParFade(i) {
    let tmp = document.getElementsByClassName("fade");

    if (!tmp[i].classList.contains("fadeIN")) {
      tmp[i].classList.toggle("fadeIN");
    }
  }
  function showPar(i) {
    let tmp = document.getElementsByClassName("slide");
    if (i != -1) {
      if (
        !tmp[i - 1].classList.contains("active-filters") &&
        !tmp[i - 1].classList.contains("news-translate")
      ) {
        if (i % 2 == 0) {
          tmp[i - 1].classList.toggle("news-translate");
        } else {
          tmp[i - 1].classList.toggle("active-filters");
        }
      }
    } else {
      if (
        !tmp[i - 1].classList.contains("active-filters") &&
        !tmp[i - 1].classList.contains("news-translate")
      ) {
        if (i % 2 == 0) {
          tmp[i - 1].classList.toggle("news-translate");
        } else {
          tmp[i - 1].classList.toggle("active-filters");
        }
      }
    }
  }
  async function deleteEvent(e) {
    console.log(e.id);
    await deleteDoc(doc(db, "events", e.id));
    deleteImage(e);
  }
  function deleteImage(e) {
    let imageRef = "events_image/" + e.id;
    const desertRef = ref(storage, imageRef);
    deleteObject(desertRef)
      .then(() => {
        console.log("eliminato");
        deleteFile(e);
      })
      .catch((error) => {
        console.log("non eliminato");
      });
  }
  function deleteFile(e) {
    let imageRef = "events_document/" + e.id;
    const desertRef = ref(storage, imageRef);
    deleteObject(desertRef)
      .then(() => {
        console.log("eliminato");
        window.location.reload();
      })
      .catch((error) => {
        console.log("non eliminato");
      });
  }
  async function updateImage(downloadURL, e) {
    const newsRef = doc(db, "events", e.target.idevent.value);
    await updateDoc(newsRef, {
      image: downloadURL,
    });
    setSubmitted(true);
    setSubmitLoading(false);
    if (e.target.file.files[0] == undefined) {
      window.location.reload();
    }
  }
  async function updateFile(downloadURL, e) {
    const newsRef = doc(db, "events", e.target.idevent.value);
    await updateDoc(newsRef, {
      file: downloadURL,
    });
    setSubmitted(true);
    setSubmitLoading(false);
    window.location.reload();
  }
  async function updateAPI(e) {
    setSubmitLoading(true);
    const newsRef = doc(db, "events", e.target.idevent.value);
    await updateDoc(newsRef, {
      id: e.target.idevent.value,
      title: e.target.eventtitle.value,
      type: e.target.type.value,
      location: e.target.location.value,
      startDate: e.target.startdate.value,
      endDate: e.target.enddate.value,
      description: e.target.description.value,
      website: e.target.website.value,
      place: e.target.place.value,
      timeZone: e.target.timezone.value,
      to_be_decided: e.target.to_be_decided.value,
      organizer: e.target.organizer.value,
    });
    if (e.target.delete != undefined && e.target.delete.checked) {
      console.log("entrato");
      let imageRef = "events_document/" + e.target.idevent.value;
      const desertRef = ref(storage, imageRef);
      deleteObject(desertRef)
        .then(async () => {
          console.log("eliminato");
          const newsRef = doc(db, "events", e.target.idevent.value);
          await updateDoc(newsRef, {
            file: "",
          });
          window.location.reload();
        })
        .catch((error) => {
          console.log("non eliminato");
        });
    }
    if (e.target.document.files[0] != undefined) {
      const storageRef = ref(
        storage,
        "events_document/" + e.target.idevent.value
      );
      const uploadTask = uploadBytesResumable(
        storageRef,
        e.target.document.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          alert(error.message, error.code);
          console.log(error.message, error.code);
        },
        () => {
          const downl = getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => {
              console.log("File available at", downloadURL);
              updateFile(downloadURL, e);
            }
          );
        }
      );
    }
    if (e.target.file.files[0] != undefined) {
      const storageRef = ref(storage, "events_image/" + e.target.idevent.value);
      const uploadTask = uploadBytesResumable(
        storageRef,
        e.target.file.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          alert(error.message, error.code);
          console.log(error.message, error.code);
        },
        () => {
          const downl = getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => {
              console.log("File available at", downloadURL);
              updateImage(downloadURL, e);
            }
          );
        }
      );
    }
    if (
      e.target.file.files[0] == undefined &&
      e.target.document.files[0] == undefined
    ) {
      setSubmitted(true);
      setSubmitLoading(false);
      window.location.reload();
    }
  }

  async function deleteNews(e) {
    await deleteDoc(doc(db, "news", e.id));
    deleteImage(e);
  }
  const [id, setId] = useState(null);
  return (
    <div className="min-h-[80vh]">
      {loading ? (
        <div>
          <div className="xl:h-[500px] md:h-[380px] h-[280px] bg-center bg-cover md:mt-[-80px] bg-black bg-opacity-70"></div>
          <div className="wrapper_load mt-[-150px]  !left-1/2 !translate-x-[-50%]">
            <div className="circle_load !bg-[#c5d4d0]"></div>
            <div className="circle_load !bg-[#c5d4d0]"></div>
            <div className="circle_load !bg-[#c5d4d0]"></div>
            <div className="shadow_load"></div>
            <div className="shadow_load"></div>
            <div className="shadow_load"></div>
            <span className="!text-[#c5d4d0]">Loading</span>
          </div>
        </div>
      ) : (
        <div
          onLoad={() => {
            if (!scrollY) {
              window.scroll(0, 0);
              setScrollY(true);
            }
          }}
        >
          <Transition.Root show={form} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={() => {
                  setForm(false),
                  setUpdate(false),
                  setUpdateEvent({
                    id: "",
                    title: "",
                    type: "",
                    location: "",
                    startDate: "",
                    endDate: "",
                    description: "",
                    image: "",
                    website: "",
                    place: "",
                    timeZone: "",
                    to_be_decided: "",
                    organizer: "",
                    file: "",
                  });
              }}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 w-window.screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl">
                      <XMarkIcon
                        className="absolute h-8 w-8 end-5 top-5 cursor-pointer "
                        onClick={() => {
                          setForm(false);
                          setSubmitted(false);
                          setUpdate(false);
                          setUpdateEvent({
                            id: "",
                            title: "",
                            type: "",
                            location: "",
                            startDate: "",
                            endDate: "",
                            description: "",
                            image: "",
                            website: "",
                            place: "",
                            timeZone: "",
                            to_be_decided: "",
                            organizer: "",
                            file: "",
                          });
                        }}
                        fill="#1f2937"
                      />
                      <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                          <div className="mt-3 text-center mx-auto sm:mt-0 sm:text-left w-4/5">
                            <Dialog.Title
                              as="h3"
                              className="text-base font-semibold leading-6 text-gray-900"
                            >
                              {update ? (
                                <div>update event</div>
                              ) : (
                                <div>upload new event</div>
                              )}
                            </Dialog.Title>
                            <div className="mt-2">
                              <form onSubmit={uploadEvent}>
                                <div className="relative z-0 w-full mb-5 group">
                                  <input
                                    type="text"
                                    name="idevent"
                                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                    placeholder=" "
                                    disabled
                                    defaultValue={id}
                                  />
                                  <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                    ID
                                  </label>
                                </div>
                                {/*event title, event type*/}
                                <div className="relative z-0 w-full mb-5 group flex flex-row space-x-4">
                                  <div className="w-full">
                                    <input
                                      type="text"
                                      name="eventtitle"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.title}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      event title
                                    </label>
                                  </div>
                                  <div className="w-full">
                                    <input
                                      type="text"
                                      name="type"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.type}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      type event
                                    </label>
                                  </div>
                                </div>
                                {/*start, end, time zone */}
                                <div className="relative z-0 w-full mb-5 group flex flex-row justify-between">
                                  <div>
                                    <input
                                      type="text"
                                      name="startdate"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.startDate}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      start date
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="text"
                                      name="enddate"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.endDate}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      end date
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="text"
                                      name="timezone"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.timeZone}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      time zone
                                    </label>
                                  </div>
                                </div>
                                {/*location, place, organizer */}
                                <div className="relative z-0 w-full mb-5 group flex flex-row justify-between">
                                  <div>
                                    <input
                                      type="text"
                                      name="location"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.location}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      location
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="text"
                                      name="place"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.place}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      place
                                    </label>
                                  </div>
                                  <div>
                                    <input
                                      type="text"
                                      name="organizer"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.organizer}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0]  rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      organizer
                                    </label>
                                  </div>
                                </div>
                                {/*to be decided*/}
                                <div className="relative z-0 w-full mb-5 group flex flex-row">
                                  <div className="w-1/3">
                                    <input
                                      type="text"
                                      name="website"
                                      id="floating_repeat_password"
                                      className="block py-2.5 px-0 w-full mb-0 mt-7 text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none    focus:outline-none focus:ring-0  peer"
                                      placeholder=" "
                                      defaultValue={updateEvent.website}
                                    />
                                    <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-9 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                      website
                                    </label>
                                  </div>
                                  <div className="w-full">
                                    <p className="text-gray-500 font-medium w-3/5 mr-0 ml-auto">
                                      to be decided
                                    </p>
                                    <select
                                      id="to_be_decided"
                                      name="to_be_decided"
                                      className="block py-2.5 px-0 w-3/5 mr-0 ml-auto text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-gray-900    focus:outline-none focus:ring-0  peer"
                                    >
                                      <option
                                        value={true}
                                        selected={
                                          updateEvent.to_be_decided == "true"
                                        }
                                      >
                                        true
                                      </option>
                                      <option
                                        value={false}
                                        selected={
                                          updateEvent.to_be_decided == "false"
                                        }
                                      >
                                        false
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                {/*text area*/}
                                <div className="relative z-0 w-full mb-5 group">
                                  <textarea
                                    id="about"
                                    name="description"
                                    placeholder="insert news text here"
                                    rows={5}
                                    className=" w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-sm sm:leading-6"
                                    defaultValue={updateEvent.description}
                                  />
                                </div>
                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                  Upload image
                                </label>
                                <input
                                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none   "
                                  type="file"
                                  name="file"
                                />

                                <label className="block mb-2 text-sm font-medium text-gray-900 ">
                                  Upload file
                                </label>
                                <input
                                  className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none   "
                                  type="file"
                                  name="document"
                                />
                                {update && updateEvent.file != "" && (
                                  <div>
                                    <input
                                      type="radio"
                                      name="delete"
                                      value="delete"
                                    />
                                    <label>delete file</label>
                                  </div>
                                )}
                                {submitLoading ? (
                                  <div
                                    role="status"
                                    className="flex flex-row border-[#80968A] border-2 rounded-lg p-2"
                                  >
                                    <svg
                                      aria-hidden="true"
                                      className="w-8 h-8 text-gray-200 animate-spin  fill-[#80968A]"
                                      viewBox="0 0 100 101"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                      />
                                      <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill"
                                      />
                                    </svg>
                                    <p className="md:text-lg text-base ml-2">
                                      uploading event...
                                    </p>
                                  </div>
                                ) : (
                                  <button
                                    type="submit"
                                    className="mt-5 bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg  text-white font-bold py-1 px-4 rounded-lg l-0 mb-5"
                                  >
                                    {update ? "save changes" : "Upload event"}
                                  </button>
                                )}
                                {submitted && (
                                  <div
                                    className="bg-green-100 border-t border-b border-green-800 text-green-700 px-4 py-3 md:mb-14 mt-5"
                                    role="alert"
                                  >
                                    <p className="md:text-lg text-base ml-2">
                                      {update
                                        ? "changes saved successfully"
                                        : "event uploaded successfully"}
                                    </p>
                                  </div>
                                )}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
          {/*banner*/}
          <div
            className="fadeIN opacity-0 w-full mx-auto bg-no-repeat xl:h-[500px] md:h-[380px] h-[280px] bg-center bg-cover md:mt-[-80px]"
            style={{ backgroundImage: `url(${Background})` }}
          >
            <div className=" md:flex md:flex-row md:justify-end justify-center xl:h-[410px] md:h-[240px] h-[140px] ">
              <div className="my-auto md:ml-auto mx-auto md:mr-32 pt-6 md:pt-0">
                <h1 className="md:text-6xl text-3xl font-bold text-white md:text-right text-center">
                  Events & More!
                </h1>
                <h3 className="md:text-3xl text-xl mt-4 text-white font-semibold md:text-right text-center md:w-auto w-11/12 mx-auto">
                  join our events that we organize around Europe!
                </h3>
              </div>
            </div>
            <div className="flex xl:flex-row flex-col bg-[#80968A] rounded-t-xl xl:w-11/12 w-11/12 md:w-10/12 xl:h-[90px] h-[140px] mx-auto">
              <div className="flex flex-row basis-1/2">
                <div className="basis-1/2 border-l-zinc-200 mx-auto flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="white"
                    className="w-8 h-8 m-1.5 my-auto mr-1 md:w-12 md:h-12 xl:w-16 xl:h-16 xl:my-3 xl:mx-2 md-my-4 md:mx-3 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <div className="flex flex-row flex-wrap my-auto">
                    <h1 className="md:text-5xl my-auto text-2xl text-white text-center xl:my-5 ">
                      {parseInt((nextDateStart - currentDate) / 86400000) + 1}
                    </h1>
                    <div className="flex xl:flex-col flex-row my-auto ml-2 xl:ml-4 xl:mt-3 ">
                      <h3 className="md:text-2xl my-auto text-xl text-white text-center ">
                        Days
                      </h3>
                      <h3 className="md:text-2xl my-auto text-xl text-white text-center  ">
                        &#160; to go
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="basis-1/2 border-l-zinc-200 border-l-[1px] mx-auto flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="white"
                    className="w-8 h-8 m-1.5 mr-1 my-auto md:w-12 md:h-12 xl:w-16 xl:h-16 xl:my-3 xl:mx-2 md-my-4 md:mx-3 flex-none"
                  >
                    <path
                      stroklinecap="round"
                      strokeLinejoin="round"
                      d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                    />
                  </svg>

                  <h3 className="md:text-2xl text-xl text-white text-left mr-2  xl:my-7 md:my-5 grow my-auto">
                    {mainEvent.place}
                  </h3>
                </div>
              </div>
              <div className="flex flex-row basis-1/2">
                <div className="basis-1/2 xl:border-l-[1px] border-l-zinc-200 xl:border-t-0 border-t-[1px]  mx-auto flex flex-row justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="white"
                    className="w-8 h-8 m-1.5 my-auto mr-1 md:w-12 md:h-12 xl:w-16 xl:h-16 xl:my-3 xl:mx-2 md-my-4 md:mx-3 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                    />
                  </svg>

                  <h3 className="md:text-2xl text-xl my-auto text-white text-left xl:my-7 grow">
                    {nextDateStart.getDate()}
                    {mainEvent.startDate != mainEvent.endDate &&
                      "-" + nextDateEnd.getDate()}
                    {" " +
                      month[nextDateStart.getMonth()] +
                      " " +
                      nextDateStart.getFullYear()}
                  </h3>
                </div>
                <div className="basis-1/2  border-l-[1px] border-l-zinc-200 xl:border-t-0 border-t-[1px] mx-auto flex flex-row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="white"
                    className="w-8 h-8 m-1.5 my-auto mr-1 md:w-12 md:h-12 xl:w-16 xl:h-16 xl:my-3 xl:mx-2 md-my-4 md:mx-3 flex-none"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                    />
                  </svg>

                  <h3 className="md:text-2xl text-xl my-auto text-white text-center  xl:my-7 ">
                    {mainEvent.type}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-11/12 w-11/12 md:w-10/12 m-auto mt-0  bg-white rounded-b-lg mb-6 pb-6">
            {/*main event*/}
            <div className="mx-auto ">
              {currentUser != null && (
                <div className="w-full mx-auto flex">
                  <button
                    className=" bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A]  md:text-lg  text-white font-bold py-1 px-4 mx-auto rounded-lg mb-5 mt-5 "
                    onClick={() => {
                      setForm(true);
                      setId(event.length + 1);
                    }}
                  >
                    {" "}
                    + Add new event
                  </button>
                </div>
              )}
              <div className="flex xl:flex-row flex-col-reverse w-11/12 pt-10 mx-auto">
                <InView
                  onChange={(inView, entry) => {
                    if (inView) {
                      setTimeout(showPar(1), 4000);
                    }
                  }}
                  className="slide xl:basis-1/2 opacity-0"
                >
                  <div className="flex flex-row justify-center">
                    <h3 className="md:text-4xl text-2xl font-bold text-center">
                      {mainEvent.title}
                    </h3>
                    {currentUser != null && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="-2 -2 28 28"
                          strokeWidth="1.5"
                          stroke="white"
                          className="w-8 h-8 rounded-full bg-green-600 ml-3 my-auto cursor-pointer flex-none"
                          onClick={() => {
                            setUpdate(true);
                            setForm(true);
                            setId(mainEvent.id);
                            setUpdateEvent(mainEvent);
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="-2 -2 28 28"
                          strokeWidth="1.5"
                          stroke="white"
                          className="w-8 h-8 rounded-full bg-red-700 ml-1 my-auto cursor-pointer flex-none"
                          onClick={() => {
                            deleteEvent(mainEvent);
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </>
                    )}
                  </div>
                  <div className="md:w-10/12 w-full mx-auto my-4">
                    <p className="md:text-lg text-base mb-4 ">
                      {mainEvent.description}
                    </p>
                   <div className="md:mx-none mx-auto">
                    <AddToCalendarButton
                      size={addCalendar}
                      hideBackground
                      buttonStyle="date"
                      name={mainEvent.title}
                      startDate={mainEvent.startDate}
                      endDate={mainEvent.endDate}
                      timeZone={mainEvent.timeZone}
                      location={mainEvent.place}
                      options="'Apple','Google','iCal','Outlook.com'"
                      lightMode="bodyScheme"
                      hideBranding
                    ></AddToCalendarButton>
                    </div> 
                    <div className="flex md:flex-row mt-10 md:justify-start justify-center ">
                    {mainEvent.website != "" && (
                      <a
                        type="button"
                        href={mainEvent.website}
                        className="bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg my-auto  text-white font-bold py-2 px-4 rounded-lg l-0"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        target="_blank"
                      >
                        More Info
                      </a>
                    )}
                    {mainEvent.file != "" && (
                      <Link
                        to={mainEvent.file}
                        target="_blank"
                        download
                        className="bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg my-auto  text-white font-bold py-2 px-4 rounded-lg ml-3"
                      >
                        Download
                      </Link>
                    )}
                      </div>
                  </div>
                </InView>
                <InView
                  onChange={(inView, entry) => {
                    if (inView) {
                      setTimeout(showPar(2), 4000);
                    }
                  }}
                  className="slide opacity-0 xl:basis-1/2 xl:w-full md:w-1/2 w-full mx-auto xl:mb-0 mb-6"
                >
                  <img src={mainEvent.image} className="w-full h-auto" />
                </InView>
              </div>
            </div>
            {/*next events*/}
            <InView
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(showParFade(0), 4000);
                }
              }}
              className="fade opacity-0 flex flex-row justify-center md:mb-24 md:mt-32 mb-12 mt-16 w-11/12 mx-auto"
            >
              <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
              <p className="md:text-4xl text-2xl flex-none font-bold text-center mx-3 w-auto text-[#80968A]">
                Next Events
              </p>
              <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
            </InView>
            <div className="w-11/12 mx-auto">
              {nextEvents.map((e, i) => {
                var date = new Date(e.startDate);
                if (i != 0) {
                  return (
                    <InView
                      onChange={(inView, entry) => {
                        if (inView) {
                          setTimeout(showPar(i + 2), 4000);
                        }
                      }}
                      key={i}
                      className="slide opacity-0 shadow-lg flex md:flex-row flex-col xl:w-4/5 w-full mx-auto rounded-lg my-4"
                    >
                      <div className="md:basis-[25%]">
                        <img
                          src={e.image}
                          className="md:rounded-l-lg md:rounded-tr-none rounded-t-lg object-cover h-full w-full"
                        />
                        {window.screen.width < 768 && (
                          <div className="mt-[-50px] h-[50px] w-full opacity-90 bg-[#80968aa7]">
                            <p className="text-white text-left text-2xl py-2 mx-auto font-bold rounded-bl-lg">
                              {e.to_be_decided == "true"
                                ? date.getDate() +
                                  " " +
                                  month[date.getMonth()].slice(0, 3) +
                                  " " +
                                  date.getFullYear()
                                : month[date.getMonth()].slice(0, 3) +
                                  " " +
                                  date.getFullYear() +
                                  ", to be decided"}
                            </p>
                          </div>
                        )}
                      </div>
                      <div className="md:basis-[73%] p-2 ">
                        <div className="flex flex-row">
                          <h2 className="md:text-2xl text-lg font-semibold ">
                            {e.title}
                          </h2>
                          {currentUser != null && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="-2 -2 28 28"
                                strokeWidth="1.5"
                                stroke="white"
                                className="w-6 h-6 rounded-full bg-green-600 ml-3 my-auto cursor-pointer"
                                onClick={() => {
                                  setUpdate(true);
                                  setForm(true);
                                  setId(e.id);
                                  setUpdateEvent(e);
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="-2 -2 28 28"
                                strokeWidth="1.5"
                                stroke="white"
                                className="w-6 h-6 rounded-full bg-red-700 ml-1 my-auto cursor-pointer"
                                onClick={() => {
                                  deleteEvent(e);
                                }}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                />
                              </svg>
                            </>
                          )}
                        </div>
                        <div className="flex flex-row justify-start xl:my-2 md:my-1 my-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4 my-1"
                          >
                            <path
                              stroklinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                            />
                          </svg>
                          <p className="md:text-lg text-base">{e.location}</p>
                        </div>

                        <p className="xl:line-clamp-3 md:line-clamp-2 line-clamp-3 md:text-lg text-base">
                          {e.description}
                        </p>
                        <div
                          className="pointer flex flex-row cursor-pointer my-2"
                          onClick={() => {
                            setOpen(true);
                            setEventView(e);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6 my-auto"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="m8.25 4.5 7.5 7.5-7.5 7.5"
                            />
                          </svg>
                          <p className="md:text-lg text-base font-semibold">
                            know more
                          </p>
                        </div>
                      </div>
                      {window.screen.width >= 768 && (
                        <div className="basis-[12%] bg-[#80968A] rounded-r-lg w-full">
                          <div className="my-[50%] flex-col align-middle flex">
                            <p className="text-w hite text-center text-3xl mx-auto font-bold text-white">
                              {e.to_be_decided == "false" && date.getDate()}
                            </p>
                            <p className="text-w hite text-center text-3xl mx-auto font-bold text-white">
                              {month[date.getMonth()].slice(0, 3)}
                            </p>
                            <p className=" text-center text-3xl mx-auto font-bold text-white">
                              {date.getFullYear()}
                            </p>
                            {e.to_be_decided == "true" && (
                              <div className="absolute  h-auto mt-auto px-[7.3px] bottom-0 bg-opacity-30 bg-black rounded-br-lg">
                                <p className="text-white text-left 2xl:text-2xl text-xl mx-auto font-bold">
                                  to be decided
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </InView>
                  );
                }
              })}
            </div>
            {/*past events*/}
            <InView
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(showParFade(1), 4000);
                }
              }}
              className="fade opacity-0 flex flex-row justify-center md:mb-24 md:mt-32 mb-12 mt-16 w-11/12 mx-auto"
            >
              <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
              <p className="md:text-4xl text-2xl flex-none font-bold text-center mx-3 w-auto text-[#80968A]">
                Past Events
              </p>
              <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
            </InView>
            <div className="w-11/12 mx-auto">
              {pastEvents.map((e, i) => {
                var date = new Date(e.startDate);

                return (
                  <InView
                    onChange={(inView, entry) => {
                      if (inView) {
                        setTimeout(showPar(nextEvents.length + i + 2), 4000);
                      }
                    }}
                    key={i}
                    className="slide opacity-0 shadow-lg flex md:flex-row flex-col xl:w-4/5 w-full mx-auto rounded-lg my-4"
                  >
                    <div className="md:basis-[25%]">
                      <img
                        src={e.image}
                        className="md:rounded-l-lg md:rounded-tr-none rounded-t-lg object-cover h-full w-full"
                      />
                      {window.screen.width < 768 && (
                        <div className="mt-[-50px] h-[50px] w-full opacity-90 bg-[#80968A]">
                          <p className="text-white text-left text-2xl mx-auto font-bold py-2 pl-2">
                            {date.getDate() +
                              " " +
                              month[date.getMonth()].slice(0, 3) +
                              " " +
                              date.getFullYear()}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="md:basis-[73%] p-2">
                      <div className="flex flex-row">
                        <h2 className="md:text-2xl text-lg font-semibold ">
                          {e.title}
                        </h2>
                        {currentUser != null && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="-2 -2 28 28"
                              strokeWidth="1.5"
                              stroke="white"
                              className="w-6 h-6 rounded-full bg-green-600 ml-3 my-auto cursor-pointer"
                              onClick={() => {
                                setUpdate(true);
                                setForm(true);
                                setId(e.id);
                                setUpdateEvent(e);
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="-2 -2 28 28"
                              strokeWidth="1.5"
                              stroke="white"
                              className="w-6 h-6 rounded-full bg-red-700 ml-1 my-auto cursor-pointer"
                              onClick={() => {
                                deleteEvent(e);
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </>
                        )}
                      </div>
                      <div className="flex flex-row justify-start xl:my-2 md:my-1 my-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-4 h-4 my-1"
                        >
                          <path
                            stroklinecap="round"
                            strokeLinejoin="round"
                            d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                          />
                        </svg>
                        <p className="md:text-lg text-base">{e.location}</p>
                      </div>

                      <p className="xl:line-clamp-3 md:line-clamp-2 line-clamp-3 md:text-lg text-base">
                        {e.description}
                      </p>
                      <div
                        className="pointer flex flex-row cursor-pointer my-2"
                        onClick={() => {
                          setOpen(true);
                          setEventView(e);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6 my-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                        <p className="md:text-lg text-base font-semibold my-auto">
                          know more
                        </p>
                      </div>
                    </div>
                    {window.screen.width >= 768 && (
                      <div className="basis-[12%] bg-[#80968A] rounded-r-lg">
                        <div className="my-[50%] flex-col align-middle flex">
                          <p className="text-w hite text-center text-3xl mx-auto font-bold text-white">
                            {date.getDate()}
                          </p>
                          <p className="text-w hite text-center text-3xl mx-auto font-bold text-white">
                            {month[date.getMonth()].slice(0, 3)}
                          </p>
                          <p className="text-w hite text-center text-3xl mx-auto font-bold text-white">
                            {date.getFullYear()}
                          </p>
                        </div>
                      </div>
                    )}
                  </InView>
                );
              })}
            </div>
          </div>

          <Transition.Root show={open} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-10"
              initialFocus={cancelButtonRef}
              onClose={setOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 md:w-full md:my-0 w-11/12 mx-auto my-2 overflow-y-auto">
                <div className="flex min-h-full justify-center  text-center items-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                      <XMarkIcon
                        className="absolute h-8 w-8 end-5 top-5 cursor-pointer "
                        onClick={() => {
                          setOpen(false);
                        }}
                        fill="#1f2937"
                      />
                      <div className="bg-white ">
                        <div className="flex flex-col justify-center md:justify-start w-full">
                          <div className="mx-auto flex w-full flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 ">
                            <img
                              className="w-full h-auto rounded-t-xl"
                              src={eventView.image}
                              aria-hidden="true"
                            />
                          </div>
                          <div className="md:ml-4 mx-auto w-[93%]">
                            <Dialog.Title
                              as="h3"
                              className="text-2xl mb-1 md:text-4xl my-3 font-semibold leading-6 text-gray-800 text-left "
                            >
                              {eventView.name}
                            </Dialog.Title>

                            <Dialog.Title
                              as="h4"
                              className="text-xl mb-1 md:text-xl font-semibold  my-3 leading-6 text-gray-800 text-left "
                            >
                              Organizer: {eventView.organizer}
                            </Dialog.Title>
                            <Dialog.Title
                              as="h4"
                              className="text-base md:text-xl font-normal  my-3 leading-6 text-gray-800 md:text-left text-justify"
                            >
                              {eventView.description}
                            </Dialog.Title>
                            {new Date(eventView.startDate) > new Date() &&
                              eventView.to_be_decided == "false" && (
                                <AddToCalendarButton
                                  size={addCalendar}
                                  hideBackground
                                  buttonStyle="date"
                                  name={eventView.title}
                                  startDate={eventView.startDate}
                                  endDate={eventView.endDate}
                                  timeZone={eventView.timeZone}
                                  location={eventView.place}
                                  options="'Apple','Google','iCal','Outlook.com'"
                                  lightMode="bodyScheme"
                                  hideBranding
                                ></AddToCalendarButton>
                              )}
                          </div>
                        </div>
                        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                          <div className="mt-2">
                            <p className="md:text-xl text-base text-gray-800    ">
                              {eventView.tasks}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="bg-gray-50 px-4 py-3 flex flex-row justify-start  sm:px-6">
                        {eventView.website != "" && (
                          <a
                            type="button"
                            href={eventView.website}
                            className=" inline-flex w-full p-4 h-1/2 my-auto justify-center rounded-md bg-white mr-2  py-2.5 text-xl font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:w-auto"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                            target="_blank"
                          >
                            More Info
                          </a>
                        )}
                        {eventView.file != "" && (
                          <Link
                            to={eventView.file}
                            target="_blank"
                            download
                            className=" inline-flex w-full p-4 h-1/2 my-auto justify-center rounded-md bg-white mr-2  py-2.5 text-xl font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50  sm:w-auto"
                          >
                            Download
                          </Link>
                        )}
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </div>
      )}
    </div>
  );
};
export default Events;
