import react from "react";
import { Fragment, useRef, useState, useEffect } from "react";
import Statistic from "../data/statistics.json";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
import {
  faEuroSign,
  faUsers,
  faGlobe,
  faCalendarDays,
} from "@fortawesome/free-solid-svg-icons";
import { useInView } from "react-intersection-observer";
import { InView } from "react-intersection-observer";
import { collection, addDoc, setDoc, doc, getDocs } from "firebase/firestore";
import { research } from "../data/researcher";
import { db, storage } from "../../firebase";

library.add(faEuroSign, faUsers, faGlobe, faCalendarDays);

const Counters = () => {
  const [responsive, setResponsive] = useState();
  const [circleresponsive, circlesetResponsive] = useState();
  const [iconSize, setIconSize] = useState();
  useEffect(() => {
    if (screen.width > 768) {
      setResponsive("130");
      circlesetResponsive("121");
      setIconSize("4x");
    } else {
      setResponsive("100");
      circlesetResponsive("94");
      setIconSize("3x");
    }
  }, []);

  function notVisible() {
    var circle = document.getElementsByClassName("circle");
    circle[0].classList.remove("active");
    circle[1].classList.remove("active");
    circle[2].classList.remove("active");
    circle[3].classList.remove("active");
  }
  function visible() {
    const counters = document.querySelectorAll(".counter");
    var circle = document.getElementsByClassName("circle");
    if (circle[0].classList.contains("active")) {
      return 0;
    }
    circle[0].classList.add("active");
    circle[1].classList.add("active");
    circle[2].classList.add("active");
    circle[3].classList.add("active");

    counters.forEach((counter) => {
      counter.innerText = "0";
      const updateCounter = () => {
        const target = +counter.getAttribute("data-target");
        const count = +counter.innerText;
        var increment = Math.trunc(target / 300);
        var speed = 1;
        if (increment == 0) {
          if (target == 48) {
            increment = 4;
            speed = 130;
          }
          if (target == 11) {
            increment = 1;
            speed = 150;
          }
          if (target == 8) {
            increment = 1;
            speed = 260;
          }
        }
        if (count < target) {
          counter.innerText = `${Math.ceil(count + increment)}`;
          setTimeout(updateCounter, speed);
        } else {
          counter.innerText = target;
          if (target == 1297200) counter.innerText = target + "€";
        }
      };
      updateCounter();
    });
  }
  return (
    <>
      <InView
        key={true}
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            visible();
          }
        }}
        className="flex flex-wrap w-full  m-auto justify-center flex-row animated-boxes"
      >
        {Statistic.map((e, i) => {
          return (
            <div key={i}>
              <svg className="lg:w-[256px] xl:mx-5 mx-2 lg:h-[256px] w-[200px] h-[200px]">
                <circle
                  className="circle"
                  cx={responsive}
                  cy={responsive}
                  r={circleresponsive}
                  stroke="#80968A"
                  strokeWidth="10"
                  fillOpacity="0"
                />
                <foreignObject className="lg:w-[256px] lg:h-[256px] w-[200px] h-[200px]">
                  <div
                    className="fixed lg:space-y-6 justify-center lg:w-64 lg:h-64 w-[200px] h-[200px] overflow-hidden flex-none rounded-full flex flex-col "
                    key={e.name}
                  >
                    <div className="mx-auto">
                      <FontAwesomeIcon
                        icon={e.icon}
                        size={iconSize}
                        color="#1f2937"
                      ></FontAwesomeIcon>
                    </div>
                    <div
                      className="mx-auto font-bold md:text-5xl text-4xl  uppercase pb-3 counter text-gray-800"
                      data-target={e.value}
                    >
                      {e.text}
                    </div>
                    <div className="mx-auto font-bold md:text-xl text-lg align-middle  pb-3 uppercase text-gray-800">
                      {e.name}
                    </div>
                  </div>
                </foreignObject>
              </svg>
            </div>
          );
        })}
      </InView>
    </>
  );
};
const Banner = () => {
  
  return (
    <>
      <div className=" fadeIN w-full md:mt-[-80px] h-auto mx-auto bg-black">
        <img
          className="w-full 2xl:h-[680px] h-auto mx-auto"
          src={require("../style/image/banner_image/home_page.jpeg")}
        />
      </div>
    </>
  );
};
const Introduction = () => {
  const navigation = useNavigate();
  function visibleIntro(i) {
    var elem = document.getElementsByClassName("animation-intro");
    if (!elem[i].classList.contains("active-news")) {
      elem[i].classList.toggle("active-news");
    }
  }
  return (
    <>
      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            setTimeout(visibleIntro(0), 4000);
          }
        }}
        className="animation-intro"
      >
        <img
          src={require("../style/image/logo.png")}
          className="md:w-2/5 w-3/5 mx-auto mb-5  pt-10"
        />
      </InView>
      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            setTimeout(visibleIntro(1), 4000);
          }
        }}
        className="animation-intro opacity-0"
      >
        <h1 className="text-center md:my-20 my-12 md:text-3xl text-xl md:w-8/12 w-11/12 mx-auto font-semibold">
          Bicyclos is a project funded by the European Union’s Horizon Europe
          Research and Innovation Programme and coordinated by the National
          Research Council in Italy.
        </h1>
      </InView>
      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            setTimeout(visibleIntro(2), 4000);
          }
        }}
        className="animation-intro opacity-0"
      >
        <div className=" md:w-4/5 w-11/12  mx-auto md:mb-36 mb-20 text-base md:text-lg">
          <p className="text-justify">
            In May 2023 the Bicyclos project has been selected for funding in
            the frame of the Marie Sklodowska-Curie Actions, that are part of
            the Excellence Pillar of the Horizon Europe Research and Innovation
            Programme. Bicyclos is a Staff Exchange project involving 9
            beneficiaries and 2 associated partners. The full title of the
            project is «Breaking down barriers to foster new Cyclodextrin-based
            applications for healthcare by implementing sustainable design
            principles». So, it’s all about Cyclodextrins, a fascinating class
            of molecules. Cyclodextrins are natural carbohydrates, obtained from
            the enzymatic degradation of starch, one of the most important
            polysaccharides in nature. Nowadays they are produced on industrial
            scale as very pure compounds and they have found multiple
            applications: food, cosmetics and pharmaceutical industry,
            chromatography, agriculture, textile and environmental remediation.
            The worldwide interest in this class of compounds is mainly due to
            their particular shape, a truncated cone where they can host a large
            variety of molecules. Not only, Cyclodextrins can also easily be
            modified with new functional groups thus improving their performance
            in various applications and opening ways to new fields of
            exploitation.
          </p>
          <div className="m-auto justify-center md:w-2/6 w-4/6 mt-5">
            <button
              to="/project/objective"
              onClick={() => {
                navigation("project/objective");
              }}
              className="bg-[#80968A] mx-auto w-full hover:bg-[#C5D4D0] hover:text-[#80968A] text-white font-bold py-2 px-4 rounded-lg "
            >
              Discover more...
            </button>
          </div>
        </div>
      </InView>
    </>
  );
};
const LatestNews = () => {
  const [news, setNews]=useState([])
  useEffect(()=>{
    const fetchData = async () => {
      let list = []
      try{
        const querySnapshot = await getDocs(collection(db, "news"));
        querySnapshot.forEach((doc) => {
          let dep = research.filter((e)=>{
            if(e.name==doc.data().member){
              return e
            }})
            let img = research.filter((e)=>{
              if(e.name==doc.data().member){
                return e
              }})
              dep = dep[0].department
              img = img[0].image
              
          list.push({department:dep, picture:img, ...doc.data()})
        });
        setNews(list.sort((a, b) => {
          let dateA = new Date(a.data);
          let dateB = new Date(b.data);
          return dateA - dateB;
        })
        .reverse().slice(0,3))
      }catch(error){
        console.log(error)
      }
    };
    fetchData()
  },[])
  
    
  const [sliceText, setSilceText] = useState();
  useEffect(() => {
    setSilceText(510);
    if (screen.width <= 768) {
      setSilceText(180);
    }
    if (screen.width > 768 && screen.width < 1440) {
      setSilceText(240);
    }
  });
  function visibleNews(i) {
    var elem = document.getElementsByClassName("news-visible");
    if (!elem[i].classList.contains("active-news")) {
      elem[i].classList.toggle("active-news");
    }
  }
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sepr",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <div onLoad={window.scrollTo({ top: 0 })}>
      <div className="md:w-5/6 w-11/12 lg:5/6 mx-auto my-12">
        <div className="flex flex-row justify-center md:mb-24 md:mt-32 mb-12 mt-16 ">
          <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
          <p className="md:text-4xl text-2xl flex-none font-bold text-center mx-3  w-auto font-4xl text-[#80968A]">
            Latest News
          </p>
          <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
        </div>
        <div className=" w-11/12 md:w-full m-auto">
          {news.map((e, i) => {
            const date = new Date(e.data);

            return (
              <InView
                as="div"
                onChange={(inView, entry) => {
                  if (inView) {
                    setTimeout(visibleNews(i), 4000);
                  }
                }}
                key={i}
                href="#"
                className="news-visible opacity-0 flex flex-col items-center w-full my-5 bg-white border border-gray-200 rounded-lg shadow md:flex-row  "
              >
                <div className="2xl:w-2/5 md:w-3/5 w-full md:h-[300px] h-[200px]">
                  <img
                    className="rounded-t-lg w-full h-full  object-cover  md:rounded-none md:rounded-s-lg"
                    src={e.image}
                    alt=""
                  />
                </div>
                <div className="flex flex-col justify-between w-full md:px-4 px-1 leading-normal ">
                  <div className="xl:mb-8 mb-5 mx-3">
                    <p className="  text-[#80968A] font-bold p-2 pl-0 pt-0 flex items-center text-lg">
                      {date.getDate() +
                        " " +
                        month[date.getMonth()] +
                        " " +
                        date.getFullYear()}
                    </p>
                    <div className="text-gray-900 font-bold text-3xl mb-2">
                      {e.title}
                    </div>
                    <p className="md:text-lg text-base line-clamp-4	 ">
                      {e.text}
                    </p>
                  </div>
                  <div className="flex items-center mx-3 mb-3">
                    <img
                      className="w-12 h-12 rounded-full mr-4"
                      src={e.picture}
                      alt="Avatar of Jonathan Reinink"
                    />
                    <div className="text-base flex flex-row w-full align-middle justify-start lg:mb-0 ">
                      <div className="flex-shrink inline-block align-middle  w-5/6 right-0 ">
                        <p className="text-gray-800 md:text-lg text-base font-semibold">
                          {e.member}
                        </p>
                        <p className="text-gray-800 font-thin md:text-lg text-base leading-none ">
                          {e.department}
                        </p>
                      </div>
                      <NavLink
                        to={`news_view/${e.id}`}
                        className="bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg my-auto  text-white font-bold py-2 px-4 rounded-lg l-0"
                      >
                        Discover
                      </NavLink>
                    </div>
                  </div>
                </div>
              </InView>
            );
          })}
        </div>
      </div>
      <InView
        as="div"
        onChange={(inView, entr) => {
          if (inView) {
            setTimeout(visibleNews(3), 4000);
          }
        }}
        className="m-auto news-visible justify-center opacity-0 md:w-2/6 w-4/6 mt-10 mb-20"
      >
        <NavLink to="/news">
          <button className="bg-[#80968A]  mx-auto w-full md:text-lg text-base hover:bg-[#C5D4D0] hover:text-[#80968A] align-middle text-white font-bold py-2 px-4 rounded-lg md:mb-10 mb-6 ">
            more news here...
          </button>
        </NavLink>
      </InView>
    </div>
  );
};
const Home = () => {
  return (
    <>
      <Banner />
      <div className="bg-white md:w-10/12 w-[95%] mx-auto rounded-b-lg md:mb-10 mb-2">
        <Introduction />
        <Counters />
        <LatestNews />
      </div>
    </>
  );
};
export default Home;
