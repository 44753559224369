import react from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookMessengerShareButton,
  VKShareButton,
  FacebookIcon,
  XIcon,
  XShareButton,
  WhatsappIcon,
  LinkedinIcon,
  FacebookMessengerIcon,
  VKIcon,
} from "react-share";
import { InView } from "react-intersection-observer";
import { collection, addDoc, setDoc, doc, getDocs } from "firebase/firestore";
import { db, storage } from "../../firebase";
import { research } from "../data/researcher";
import { useLocation } from "react-router-dom";
const NewsView = () => {
  const [allNews, setAllNews] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (screen.width > 844) {
      setIconSize(32);
      setSlide(500);
    } else {
      setIconSize(24);
      setSlide(200);
    }

    const fetchData = async () => {
      let list = [];
      try {
        const querySnapshot = await getDocs(collection(db, "news"));
        querySnapshot.forEach((doc) => {
          let dep = research.filter((e) => {
            if (e.name == doc.data().member) {
              return e;
            }
          });
          let img = research.filter((e) => {
            if (e.name == doc.data().member) {
              return e;
            }
          });
          dep = dep[0].department;
          img = img[0].image;

          list.push({ department: dep, picture: img, ...doc.data() });
        });
        setAllNews(
          list
            .sort((a, b) => {
              let dateA = new Date(a.data);
              let dateB = new Date(b.data);
              return dateA - dateB;
            })
            .reverse()
        );
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const params = useParams();
  const data = allNews;
  const [iconSize, setIconSize] = useState();
  const [relatedNews, setRelatedNews] = useState([{}]);
  const [slide, setSlide] = useState();
  const [width, setWidth] = useState("0%");
  const navigate = useNavigate();

  var news = allNews.filter((e) => {
    return e.id == params.id;
  });

  useEffect(() => {
    let tmp = allNews.filter((e) => {
      return e.department == news[0].department || e.member == news[0].member;
    });
    let duplicate = [];
    tmp.forEach((element) => {
      if (!duplicate.includes(element) && element != news[0]) {
        duplicate.push(element);
      }
    });
    if (duplicate.length < 5) {
      allNews.map((e) => {
        if (!duplicate.includes(e) && duplicate.length < 5) {
          duplicate.push(e);
        }
      });
    }
    setRelatedNews(duplicate);
  }, [loading]);

  const slideLeft = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft - slide;
    var scrollPercentage =
      (100 * (slider.scrollLeft - 500)) /
      (slider.scrollWidth - slider.clientWidth);
    if (scrollPercentage < 0) {
      scrollPercentage = 0;
    }
    var progressBar = document.getElementsByClassName("progressive");
    progressBar[0].style.width = `${scrollPercentage}%`;
  };
  function scrollToTop() {
    var timerHandle = setInterval(function () {
      if (
        document.body.scrollTop != 0 ||
        document.documentElement.scrollTop != 0
      )
        window.scrollTo({ top: 0 });
      else clearInterval(timerHandle);
    }, 2);
  }
  let tmp = document.getElementsByClassName("bottomEntry");
  for (let i = 0; i < tmp.length; i++) {
    tmp[i].classList.remove("active-news");
  }
  tmp = document.getElementsByClassName("topEntry");
  for (let i = 0; i < tmp.length; i++) {
    tmp[i].classList.remove("active-news-reverse");
  }
  const slideRight = () => {
    var slider = document.getElementById("slider");
    slider.scrollLeft = slider.scrollLeft + slide;
    var scrollPercentage =
      (100 * (slider.scrollLeft + 500)) /
      (slider.scrollWidth - slider.clientWidth);
    if (scrollPercentage > 100) {
      scrollPercentage = 100;
    }
    var progressBar = document.getElementsByClassName("progressive");
    progressBar[0].style.width = `${scrollPercentage}%`;
  };
  if (!loading) {
    var dataNews = new Date(news[0].data);
  }

  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  function topEntry(i) {
    const el = document.getElementsByClassName("topEntry");
    if (!el[i].classList.contains("active-news-reverse")) {
      el[i].classList.toggle("active-news-reverse");
    }
  }
  function bottomEntry(i) {
    const el = document.getElementsByClassName("bottomEntry");
    if (!el[i].classList.contains("active-news")) {
      el[i].classList.toggle("active-news");
    }
  }
  return (
    <div className="min-h-[80vh]">
      {!loading ? (
        <div
          className="md:mt-20 mt-14 ease-linear duration-500 bg-white w-11/12 rounded-lg mx-auto py-5 mb-10 shadow-lg"
          onLoad={scrollToTop()}
        >
          <div className="w-4/5 mx-auto md:mt-20 mb-10">
            <InView
              as="div"
              className="w-full opacity-0 topEntry"
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(topEntry(0), 4000);
                }
              }}
            >
              <img
                className="h-full md:w-[70%] w-full mx-auto image-entry"
                src={news[0].image}
              />
            </InView>
            <InView
              className="md:w-4/5 w-full mx-auto bottomEntry opacity-0"
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(bottomEntry(0), 4000);
                }
              }}
            >
              <div className="md:text-4xl text-2xl font-bold text-left md:mt-10 mt-6">
                <h1>{news[0].title}</h1>
              </div>
              <div className="md:text-xl text-lg  text-left md:mt-10 mt-5">
                <h1>
                  {dataNews.getDate() +
                    " " +
                    month[dataNews.getMonth()] +
                    " " +
                    dataNews.getFullYear()}
                </h1>
              </div>
              <div className="w-full text-justify mt-4 text-base md:text-lg">
                <p>{news[0].text}</p>
              </div>
            </InView>
            <InView
              as="div"
              className="flex md:w-4/5 w-full mx-auto items-center mb-3 mt-5 opacity-0 bottomEntry"
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(bottomEntry(1), 4000);
                }
              }}
            >
              <img
                className="w-10 h-10 rounded-full mr-4"
                src={news[0].picture}
                alt="Avatar of Jonathan Reinink"
              />
              <div className="text-sm flex flex-row w-full align-middle justify-start lg:mb-0 ">
                <div className="flex-shrink inline-block align-middle  w-5/6 right-0 ">
                  <p className="text-gray-900 ">{news[0].member}</p>
                  <p className="text-gray-900 font-thin">
                    {news[0].department}
                  </p>
                </div>
              </div>
            </InView>
          </div>
          <InView
            as="div"
            className="border-b-[2px] border-t-[2px] border-[#80968A] w-4/5 mx-auto bottomEntry opacity-0"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(bottomEntry(2), 4000);
              }
            }}
          >
            <div className="flex flex-row md:justify-end justify-start w-11/12 mx-auto md:my-5 my-2 flex-wrap md:flex-nowrap">
              <div className="w-full md:mb-0 mb-2">
                <p className="text-[#80968A] md:text-2xl text-lg font-bold">
                  share it!
                </p>
              </div>
              <div className="flex md:space-x-4 space-x-2 h-9 ">
                <FacebookShareButton url={"www.google.it"}>
                  <FacebookIcon size={iconSize} round />
                </FacebookShareButton>

                <WhatsappShareButton url={"www.google.it"}>
                  <WhatsappIcon size={iconSize} round />
                </WhatsappShareButton>

                <FacebookMessengerShareButton url={"www.google.it"}>
                  <FacebookMessengerIcon size={iconSize} round />
                </FacebookMessengerShareButton>

                <LinkedinShareButton url={"www.google.it"}>
                  <LinkedinIcon size={iconSize} round />
                </LinkedinShareButton>
                <TwitterShareButton url={"www.google.it"}>
                  <XIcon size={iconSize} round />
                </TwitterShareButton>
                <VKShareButton url={"www.google.it"}>
                  <VKIcon size={iconSize} round />
                </VKShareButton>
              </div>
            </div>
          </InView>
          <div className="mt-5">
            <InView
              as="div"
              className="md:w-4/5 w-11/12 mx-auto flex flex-row justify-center md:mb-24 md:mt-32 mb-12 mt-16 bottomEntry opacity-0"
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(bottomEntry(3, 4000));
                }
              }}
            >
              <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
              <p className="md:text-5xl text-2xl flex-none font-bold text-center mx-3  w-auto  text-[#80968A]">
                Related News
              </p>
              <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
            </InView>
            <InView
              as="div"
              id="indicators-carousel"
              className="relative 2xl:w-4/5 md:w-10/12 w-[98%] mx-auto md:mb-40 mb-20 bottomEntry opacity-0"
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(bottomEntry(4), 4000);
                }
              }}
            >
              <div
                id="slider"
                className={
                  relatedNews.length < 4
                    ? "shadow-lg scroll-smooth relative flex flex-nowrap h-auto  rounded-lg md:h-auto duration-300 w-full mx-auto overflow-x-scroll md:overflow-hidden overflow-y-hidden justify-center"
                    : "shadow-lg scroll-smooth relative flex flex-nowrap h-auto  rounded-lg md:h-auto duration-300 w-full mx-auto overflow-x-scroll lg:overflow-hidden overflow-y-hidden"
                }
              >
                {relatedNews.length != 1 &&
                  relatedNews.map((e, i) => {
                    var classname =
                      "flex-none flex flex-col items-center w-[200px] md:w-[300px] 2xl:h-[470px] md:h-[500px] h-[420px] mx-2  md:mx-5 bg-white border border-gray-200 rounded-lg shadow";
                    if (i == 0) {
                      classname =
                        "flex-none flex flex-col items-center w-[200px] md:w-[300px] 2xl:h-[470px] md:h-[500px] h-[420px] md:mr-5 mr-2 bg-white border border-gray-200 rounded-lg shadow";
                    }
                    if (i == relatedNews.length - 1) {
                      classname =
                        "flex-none flex flex-col items-center w-[200px] md:w-[300px] 2xl:h-[470px] md:h-[500px] h-[420px]  md:ml-5 ml-2  bg-white border border-gray-200 rounded-lg shadow";
                    }
                    var date = new Date(e.data);
                    return (
                      <div key={i}>
                        <div
                          onClick={() => {
                            if (screen.width < 844)
                              navigate(`../news_view/${e.id}`);
                          }}
                          className={classname}
                        >
                          <img
                            className="object-cover w-full rounded-t-lg h-auto overflow-hidden object-top "
                            src={e.image}
                            alt=""
                          />
                          <div className="flex flex-col justify-between w-full h-full md:px-2  leading-normal ">
                            <div className="xl:mb-8 mb-5 mx-3">
                              <p className="  text-[#80968A] font-bold p-2 pl-0 pt-0 flex items-center text-lg">
                                {date.getDate() +
                                  " " +
                                  month[date.getMonth()].slice(0, 3) +
                                  " " +
                                  date.getFullYear()}
                              </p>
                              <div className="text-gray-900 font-bold text-xl mb-2">
                                {e.title}
                              </div>
                              <p className="text-base line-clamp-3">
                                {e.text}...
                              </p>
                            </div>
                            <div className="flex items-center mt-auto mx-1 mb-3">
                              <img
                                className="w-10 h-10 rounded-full md:mr-2 mx-auto"
                                src={e.picture}
                                alt="Avatar of Jonathan Reinink"
                              />
                              <div className="text-base flex flex-row w-full align-middle justify-start lg:mb-0 ">
                                <div className="flex-shrink inline-block align-middle   w-11/12 mx-auto right-0 ">
                                  <p className="text-gray-900 font-semibold">
                                    {e.member}
                                  </p>
                                  <p className="text-gray-900 font-thin">
                                    {e.department}
                                  </p>
                                </div>
                              </div>
                              <NavLink
                                to={`../news_view/${e.id}`}
                                className="bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] align-middle hidden md:block text-white font-bold py-2 px-4 rounded-lg l-0"
                              >
                                Discover
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {relatedNews.length > 3 && screen.width > 844 && (
                <>
                  <button
                    type="button"
                    onClick={slideLeft}
                    className="absolute top-0 start-0 z-30 flex items-center justify-center h-full cursor-pointer group focus:outline-none"
                    data-carousel-prev
                  >
                    <span className="inline-flex items-center opacity-80 justify-center w-10 h-10 rounded-full bg-[#80968A]  group-hover:bg-[#C5D4D0] ">
                      <svg
                        className="w-4 h-4 text-black  rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="#95340A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                      <span className="sr-only">Previous</span>
                    </span>
                  </button>
                  <button
                    type="button"
                    onClick={slideRight}
                    className="absolute top-0 end-0 z-30 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
                  >
                    <span className="inline-flex opacity-80 items-center justify-center w-10 h-10 rounded-full bg-[#80968A]  group-hover:bg-[#C5D4D0]  ">
                      <svg
                        className="w-4 h-4 text-black  rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="#95340A"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span className="sr-only">Next</span>
                    </span>
                  </button>

                  <div className="w-full min-h-[4px] rounded-full hidden md:block bg-gray-200">
                    <div
                      className={`bg-[#80968A] min-h-[4px] rounded-full progressive w-0 ease-linear duration-500`}
                    ></div>
                  </div>
                </>
              )}
            </InView>
          </div>
        </div>
      ) : (
        <div className="wrapper_load mt-40 lg:ml-[35vw]  md:ml-[25vw] ml-0">
          <div className="circle_load"></div>
          <div className="circle_load"></div>
          <div className="circle_load"></div>
          <div className="shadow_load"></div>
          <div className="shadow_load"></div>
          <div className="shadow_load"></div>
          <span>Loading</span>
        </div>
      )}
    </div>
  );
};
export default NewsView;
