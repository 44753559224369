import react from "react";
import { useParams } from "react-router-dom";
import { InView } from "react-intersection-observer";
import { useState, useRef, useEffect } from "react";
const Project = () => {
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  useEffect(() => {
    if (params.id != "objective") {
      if (loaded) {
        document
          .getElementById(params.id)
          .scrollIntoView({ behavior: "smooth" });
      } else {
        document.getElementById(params.id).scrollIntoView();
      }
    } else {
      if (loaded) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 0,
        });
      }
    }
    setTimeout(scrollWindow(false), 1000);
  }, [params]);
  function scrollWindow(bool) {
    if (!loaded) {
      const classnames = ["obj", "act", "workPlan"];

      for (let i = 0; i < classnames.length; i++) {
        let tmp = document.getElementsByClassName(classnames[i]);
        for (let j = 0; j < tmp.length; j++) {
          tmp[j].classList.remove("active-filters");
          tmp[j].classList.remove("news-translate");
        }
      }
      document
        .getElementsByClassName("phase")[0]
        .classList.remove("active-news");
      document
        .getElementsByClassName("phase")[1]
        .classList.remove("active-news");
      document
        .getElementsByClassName("phase")[2]
        .classList.remove("active-news");
      document
        .getElementsByClassName("phase")[3]
        .classList.remove("active-news");
      if (params.id == "objective") {
        let tmp = document.getElementsByClassName("obj");
        tmp[0].classList.add("active-filters");
        tmp[1].classList.add("news-translate");
        tmp[2].classList.add("active-filters");
        tmp[3].classList.add("news-translate");
      }
      if (params.id == "activities") {
        let tmp = document.getElementsByClassName("act");
        tmp[0].classList.add("active-filters");
        tmp[1].classList.add("news-translate");
        tmp = document.getElementsByClassName("phase");
        tmp[0].classList.add("active-news");
        tmp[1].classList.add("active-news");
      }
      if (params.id == "work_plan") {
        let tmp = document.getElementsByClassName("workPlan");
        tmp[0].classList.add("active-filters");
        tmp[1].classList.add("news-translate");
      }
    }

    setLoaded(true);
  }
  function visibleIntro(name, i, n) {
    const div = document.getElementsByClassName(name);
    if (i == 0) {
      if (!div[n].classList.contains("active-filters")) {
        div[n].classList.toggle("active-filters");
      }
    } else if (i == 1) {
      if (!div[n].classList.contains("news-translate")) {
        div[n].classList.toggle("news-translate");
      }
    } else {
      if (!div[n].classList.contains("active-news")) {
        div[n].classList.toggle("active-news");
      }
    }
  }
  return (
    <div
      id="objective"
      className="w-[87%] px-[3%] mx-auto bg-white rounded-b-lg"
    >
      {/*main object */}
      <div className="">
        <div className=" w-full m-auto md:pt-30 pt-16 mb-15 flex xl:flex-row flex-col ">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("obj", 0, 0), 4000);
              }
            }}
            className="basis-1/2 my-auto xl:mr-6 mx-auto obj"
          >
            {" "}
            <h1 className=" font-bold md:text-5xl text-2xl text-gray-800">
              Main Objective
            </h1>
            <p className="mt-5 my-5  md:text-lg  text-base text-justify text-gray-800">
              The main objective of Bicyclos is to bring cyclodextrins (CD) and
              their derivatives closer to practical applications by implementing
              a virtuous process that sustains eventual exploitation at
              industrial level. In clinical applications we want to launch some
              cyclodextrins as new API (Active Pharmaceutical Ingredient),
              commonly more known as «drugs», and others as delivery systems of
              already known drugs or therapeutic nucleic acids. We also plan the
              release of new biosensors made of cyclodextrin for diagnostic
              purposes.
            </p>
          </InView>
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("obj", 1, 1), 4000);
              }
            }}
            className="basis-1/2 justify-end obj align-middle"
          >
            <img
              className="h-auto md:w-[80%] w-full mx-auto"
              src={require("../style/image/project/Goal_freccia.jpeg")}
            />
          </InView>
        </div>

        <div className="w-full mx-auto md:mt-20 mt-8 mb-20 flex xl:flex-row flex-col">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("obj", 0, 2), 4000);
              }
            }}
            className="basis-1/2  right-0 order-last md:order-first obj"
          >
            <img
              className="h-auto md:w-[80%] w-full mx-auto my-auto md:mt-10"
              src={require("../style/image/project/4_frecce.jpeg")}
            />
          </InView>
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("obj", 1, 3), 4000);
              }
            }}
            className="basis-1/2 my-auto obj"
          >
            <h2 className="md:text-3xl text-lg font-semibold mb-5 text-gray-800">
              To reach our objective, we identified four specific research
              goals:
            </h2>
            <ol className=" my-5  text-gray-800  ">
              <li className="md:my-3 xl:h-11 lg:h-13 flex md:flex-row flex-col my-2 h-20 max-h-30 p-1 md:mx-0 justify-center md:justify-start md:text-lg text-base  text-gray-800 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#089994"
                  className="flex-none md:w-10 md:h-10 w-6 h-6 md:my-auto md:mx-1 mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <p className="my-auto ">
                  The design of sustainable syntheses or optimization of
                  existing protocols to prepare new CD-based systems.
                </p>
              </li>
              <li className="md:my-3 xl:h-11 lg:h-13 flex md:flex-row flex-col my-2 h-20 max-h-30 p-1 md:mx-0 justify-center md:justify-start md:text-lg text-base  text-gray-800 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#089994"
                  className="flex-none md:w-10 md:h-10 w-6 h-6 md:my-auto  md:mx-1 mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <p className="my-auto">
                  The characterization of new CDs as APIs or delivery systems.
                  The characterization of new CDs as sensing systems of
                  biomolecules.
                </p>
              </li>
              <li className="md:my-3 xl:h-11 lg:h-11   flex md:flex-row flex-col my-2 h-20 p-1 md:mx-0 justify-center md:justify-start md:text-lg text-base  text-gray-800 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#089994"
                  className="flex-none md:w-10 md:h-10 w-6 h-6 md:my-auto  md:mx-1 mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <p className="my-auto">
                  Better understanding of and new knowledge generation on the
                  biological processes activated by CDs
                </p>
              </li>
              <li className="md:my-3 xl:h-11 lg:h-11  my-2 flex md:flex-row flex-col h-20 max-h-30 p-1 md:mx-0 justify-center md:justify-start md:text-lg text-base  text-gray-800 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#089994"
                  className="flex-none md:w-10 md:h-10 w-6 h-6 md:my-auto md:mx-1 mx-auto"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                <p className="my-auto">
                  The engineering of industrial production processes of the new
                  CDs that allow them to be compliant with GMP guidelines and
                  regulatory requirements in drug design.
                </p>
              </li>
            </ol>
          </InView>
        </div>
      </div>
      {/*activities*/}
      <div className="mb-10 mt-16 scroll-mt-30 " id="activities">
        {/*intro*/}
        <div className="border-t h-1 w-full border-gray-300 m-auto"></div>
        <div className="w-full m-auto  mt-16 mb-16 flex xl:flex-row flex-col ">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("act", 0, 0), 4000);
              }
            }}
            className=" act basis-1/2 my-auto right-0 order-last md:order-first opacity-0"
          >
            <h1 className=" font-bold md:text-5xl text-2xl text-gray-800 md:my-5 mb-3 mt-5">
              Activities
            </h1>
            <div className="md:text-lg  text-base text-justify text-gray-800 my-auto">
              <p className="font-[500]">
                {" "}
                As shown in the figure alongside, the project develops in 4
                different phases:As shown in the figure alongside, 4 types of
                activities are planned for the project:
              </p>
              <ol className="list-decimal ml-4 mt-4">
                <li className="my-1">
                  Working on the development of sustainable syntheses or the
                  optimization of existing protocols to find new ways to prepare
                  CD-based systems;
                </li>
                <li className="my-1">
                  Working on the development of sustainable syntheses or the
                  optimization of existing protocols to find new ways to prepare
                  CD-based systems;
                </li>
                <li className="my-1">
                  Studying the biological processes in 2D and 3D models
                  activated by CDs either as standalone agent or as delivery
                  system loaded with a cargo of therapeutic interest;
                </li>
                <li className="my-1">
                  Creating a database with the information obtained along
                  Bicyclos and that available in literature in support of
                  sustainable CD production.
                </li>
              </ol>
            </div>
          </InView>
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("act", 1, 1), 4000);
              }
            }}
            className="basis-1/2 my-auto opacity-0 act"
          >
            <img
              src={require("../style/image/project/project_circle.png")}
              className="md:w-[85%] w-full h-auto mx-auto md:mt-8"
            />
          </InView>
        </div>
        {/*phases*/}
        <div className="flex flex-col xl:w-10/12 md:w-10/12 w-11/12 mx-auto">
          <div className="flex xl:flex-row flex-col  w-full justify-center  xl:space-x-10 xl:space-y-0 space-y-4 md:mt-5">
            <InView
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(visibleIntro("phase", 2, 0), 4000);
                }
              }}
              className="phase opacity-0 flex flex-col md:mx-2  mx-auto justify-center   md:mt-0 mt-5 xl:basis-3/6 md:basis-1/2"
            >
              <div className="mx-auto mb-[-20px] mix-blend-darken h-10 w-10 bg-[#80968A] rounded-full justify-center">
                <p className=" text-center mt-1 h-5 font-semibold text-gray-100 text-xl">
                  1
                </p>
              </div>
              <div className="shadow md:p-7 p-4 pt-6 xl:h-[440px]   text-base md:text-lg rounded-lg text-justify bg-white">
                The design and synthesis of new “green” CDs according to
                principles of circular chemistry: this requires the invention of
                new synthetic procedures or the optimization of existing
                protocols. It is a very challenging activity as, up to now,
                synthetic protocols have often ignored the working principles of
                sustainable and circular chemistry and cannot be upscaled
                industrially. The case of CD-based polymers is even more
                challenging as the currently used protocols cannot guarantee
                reproducible batches. Accurate structural characterization of
                the prepared library of new CD monomers and polymers also needs
                important innovations and the combination of different
                techniques following new experimental protocols.
              </div>
            </InView>
            <InView
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(visibleIntro("phase", 2, 1), 4000);
                }
              }}
              className="phase opacity-0 flex flex-col md:mx-2  mx-auto justify-center   md:mt-0 mt-5 xl:basis-3/6 md:basis-1/2"
            >
              <div className="mx-auto mb-[-20px] mix-blend-darken  h-10 w-10 bg-[#80968A] rounded-full justify-center">
                <p className=" text-center mt-1 h-5 font-semibold text-gray-100 text-xl">
                  2
                </p>
              </div>
              <div className="shadow md:p-7 p-4 pt-6  xl:h-[440px]  bg-white  text-base  md:text-lg rounded-lg text-justify">
                Investigation of the new library of CD-based systems as APIs,
                delivery systems for drugs and nucleic acids or receptors for
                chemical sensing of biologicals. So, the activity concerns the
                study of the interaction of the CD-based delivery systems with
                biologically active ingredients such as oligonucleotides and
                drugs selected by the partners on basis of their previous
                experience and the target diseases. To simplify the system, we
                choose an approach based on supramolecular host/ CD guest
                binding. Part of the activities cover the study of the
                interaction of the CD-based sensing systems with biological
                compounds in order to assess electrochemical sensing
                capabilities of the new CDs and proceed with the development of
                a marketable sensing device.
              </div>
            </InView>
          </div>
          <div className="flex xl:flex-row flex-col  w-full justify-center xl:mt-10 xl:space-x-10 xl:space-y-0 space-y-4 md:mt-5">
            <InView
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(visibleIntro("phase", 2, 2), 4000);
                }
              }}
              className="phase opacity-0 flex flex-col md:mx-2 md:w-auto mx-auto justify-center   md:mt-0 mt-5 xl:basis-3/6 md:basis-1/2"
            >
              <div className="mx-auto mb-[-20px] mix-blend-darken  h-10 w-10 bg-[#80968A] rounded-full justify-center">
                <p className=" text-center mt-1 h-5 font-semibold text-gray-100 text-xl">
                  3
                </p>
              </div>
              <div className="shadow md:p-7 p-4 pt-6 xl:h-[440px]   bg-white  text-base md:text-lg rounded-lg text-justify">
                Biological tests in 2D and 3D models of the CD systems. We will
                use different approaches that depend on the target diseases. In
                case of CDs as APIs their cytotoxicity will be tested, and we
                will study their distribution in simple model organisms. In the
                case of CD delivery systems, the biological activity of both
                therapeutic guest and CD host will be explored. Study of the CDs
                in biological systems is not easy as many approaches in biology
                rely on fluorescence signals, property not possessed by the
                natural CDs. So, we will explore new techniques like Raman
                spectroscopic microscopy for screening and analysis of the new
                CD-based systems.
              </div>
            </InView>
            <InView
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(visibleIntro("phase", 2, 3), 4000);
                }
              }}
              className="phase opacity-0 flex flex-col md:mx-2 md:w-auto mx-auto justify-center   md:mt-0 mt-5 xl:basis-3/6 md:basis-1/2"
            >
              <div className="mx-auto mb-[-20px] mix-blend-darken  h-10 w-10 bg-[#80968A] rounded-full justify-center">
                <p className=" text-center mt-1 h-5 font-semibold text-gray-100 text-xl">
                  4
                </p>
              </div>
              <div className="shadow md:p-7 p-4 pt-6  xl:h-[440px]  bg-white text-base md:text-lg rounded-lg text-justify">
                the construction of a database to support all activities. There
                are large amounts of literature available on the preparation of
                CDs and also a lot of patents exist. However, often protocols
                are used that are neither sustainable on large scale nor
                compliant with circular economy. Our plan is to select and
                collect already existing and new information on CD chemistry and
                biology from scientific publications, patents and open data
                repositories, that can indeed foster sustainable development of
                CDs. This information is crucial to support the other activities
                of Bicyclos. The information will then further be completed with
                the knowledge generated along the Bicyclos project.
              </div>
            </InView>
          </div>
        </div>
      </div>
      {/*work plan */}
      <div className="my-20 scroll-mt-32 " id="work_plan">
        <div className="border-t my-10 h-1 w-full border-gray-300 m-auto "></div>
        <div className="flex xl:flex-row  flex-col-reverse w-full mx-auto">
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("workPlan", 0, 0), 4000);
              }
            }}
            className="workPlan mx-auto opacity-0 xl:w-5/12 md:w-11/12 w-full xl:basis-1/2"
          >
            <div className="">
              <h1 className="font-bold md:text-5xl text-2xl text-gray-800 md:my-5 mb-3 mt-5">
                Work Plan
              </h1>
              <p className="mt-5 my-5  md:text-lg   text-base text-justify text-gray-800">
                The success and the impact of the project strongly depend on an
                effective work plan. As Bicyclos is a staff exchange project,
                training of researchers is the first mean to reach the
                scientific goals of Bicyclos and drive progress in the CD field.
                The project offers the opportunity to research staff of all
                levels, young and experienced, to take part in the research and
                innovation activities during their visits near the partners of
                the consortium. Depending on the host institution, they can
                acquire skills in CD synthesis, characterization, biology and
                therapeutic formulations. During the visits, transfer of
                knowledge mainly operated by experienced staff is expected to
                foster professional growth of visiting staff. All the results of
                Bicyclos will than undergo a process either of knowledge sharing
                with private and public scientific community or exploitation to
                transform results in new products, prototypes, etc. All staff
                involved in Bicyclos will be engaged in different communication
                activities that involve large public of every age either locally
                or in internet and on social platforms. To maximize the impact a
                good management is mandatory and involves not only the CNR
                coordinator but the whole team of Bicyclos.
              </p>
            </div>
          </InView>
          <InView
            as="div"
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visibleIntro("workPlan", 1, 1), 4000);
              }
            }}
            className="workPlan mx-auto xl:w-5/12 md:w-9/12 w-full xl:basis-1/2 opacity-0"
            id="work_plan"
          >
            <img
              src={require("../style/image/project/workPlan.png")}
              className="xl:w-11/12 w-full h-auto xl:ml-auto mx-auto my-5"
            />
          </InView>
        </div>
      </div>
    </div>
  );
};
export default Project;
