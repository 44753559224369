import { useState, useEffect, useRef, Fragment } from "react";
import { InView } from "react-intersection-observer";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { partners } from "../data/partner";
const Partners = () => {
  const [resp, setResp] = useState();
  const [show, setShow] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [scrollY, setScrollY] = useState(false)
  useEffect(() => {
    if (screen.width <= 1024) {
      setResp("auto");
    } else {
      setResp("380px");
    }
  }, [screen.width]);
  function visiblePartner(i) {
    var div = document.getElementsByClassName("partnerView");
    if (!div[i].classList.contains("active-news")) {
      div[i].classList.toggle("active-news");
    }
  }
  const showExtension = (name, i) => {
    var div = document.getElementById(name);
    if (div.style.height == "0px" || div.style.height == "") {
      div.style.height = resp;
      let tmp = show;
      tmp[i] = !tmp[i];
      setShow([...tmp]);
    } else {
      div.style.height = "0px";
      let tmp = show;
      tmp[i] = !tmp[i];
      setShow([...tmp]);
    }
  };
  const [currentUser, setCurrentUser] = useState(partners[0].researcher[0]);
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  return (
    <div onLoad={()=>{
      if(!scrollY)
      {
        window.scrollTo({top:0})
        setScrollY(true)
      }
    }}>
      <div className="flex flex-row justify-center md:mb-24 md:mt-32 mb-12 mt-16 w-4/5 mx-auto" >
        <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
        <p className="md:text-5xl text-3xl flex-none font-bold text-center mx-3  w-auto  text-[#80968A]">
          Partners
        </p>
        <div className="w-full my-auto h-2 border-b-2 border-t-2 border-gray-300"></div>
      </div>
      {partners.map((item, i) => {
        return (
          <InView
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visiblePartner(i), 4000);
              }
            }}
            className="partnerView opacity-0"
            key={item.name}
          >
            <div className="relative my-8 w-4/5 mx-auto rounded-lg shadow-lg bg-white">
              <div className="mb-0 md:h-[180px] h-[115px]">
                <img
                  src={item.image}
                  className="md:h-[130px] h-[70px] w-auto rounded-tl-lg"
                />
                <div className="flex flex-row justify-end w-full md:mt-2 mt-1.5">
                  <button
                    onClick={() => {
                      showExtension(item.name, i);
                    }}
                    className="relative flex flex-row items-center md:ml-4  ml-4  text-base md:text-xl w-full font-semibold text-left transition-all  cursor-pointer "
                    data-collapse-target="animated-collapse-1"
                  >
                    {show[i] ? (
                      <>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 15.75l7.5-7.5 7.5 7.5"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </>
                    )}
                  </button>
                  <a
                    target="_blank"
                    href={item.website}
                    className="bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:w-52 w-full md:text-lg align-middle text-center text-white font-bold md:py-1 md:px-2 p-1 rounded-lg mr-4"
                  >
                    official website
                  </a>
                </div>
              </div>
              <div
                id={item.name}
                data-collapse="animated-collapse-1"
                className="h-[0px] overflow-hidden transition-all duration-300 ease-in"
              >
                <div className="p-4 md:text-lg text-base  leading-normal text-gray-800">
                  {item.description}
                </div>
                <div className="flex flex-row flex-wrap md:justify-start justify-center">
                  {item.researcher.map((e, i) => {
                    return (
                      <div
                        className="flex flex-col mx-5 cursor-pointer"
                        key={i}
                        onClick={() => {
                          setCurrentUser(e);
                          setOpen(true);
                        }}
                      >
                        <div className="w-20 h-20 mx-auto rounded-full">
                          <img
                            src={e.image}
                            className="h-20 w-20 rounded-full"
                          />
                        </div>
                        <div className="md:text-lg text-base">{e.name}</div>
                        <div className="md:text-lg text-base">{e.role}</div>
                      </div>
                    );
                  })}
                  <div></div>
                </div>
              </div>
            </div>
          </InView>
        );
      })}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <XMarkIcon
                    className="absolute h-8 w-8 end-5 top-5 cursor-pointer "
                    onClick={() => {
                      setOpen(false);
                    }}
                    fill="#1f2937"
                  />
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="flex md:flex-row flex-col justify-center md:justify-start w-full">
                      <div className="mx-auto flex h-20 w-20 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 ">
                        <img
                          className="h-20 w-20 rounded-full"
                          src={currentUser.image}
                          aria-hidden="true"
                        />
                      </div>
                      <div className="md:ml-4 mx-auto">
                        <Dialog.Title
                          as="h3"
                          className="text-base md:text-lg font-semibold leading-6 text-gray-800 md:text-left text-center"
                        >
                          {currentUser.name}
                        </Dialog.Title>
                        <Dialog.Title
                          as="h4"
                          className="text-base md:text-lg font-normal  leading-6 text-gray-800 md:text-left text-center"
                        >
                          {currentUser.role}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-2">
                        <p className="md:text-lg text-base text-gray-800    ">
                          {currentUser.tasks}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <a
                      type="button"
                      href={currentUser.personalWebsite}
                      className="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md bg-white px-3 py-2 md:text-lg text-base font-semibold text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                      target='_blank'
                    >
                      Personal Website
                    </a>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <InView
            onChange={(inView, entry) => {
              if (inView) {
                setTimeout(visiblePartner(partners.length), 4000);
              }
            }}
            className="partnerView opacity-0 w-4/5 mx-auto"
            
          >

      <iframe className="w-full md:h-[576px] h-[300px] mb-10 shadow-lg rounded-lg" src="https://maphub.net/embed_h/DkzS3EtBtKHaF6lk?panel=1&panel_closed=1" frameBorder="0"></iframe>
      </InView>
    </div>
  );
};
export default Partners;
