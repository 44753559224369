import React from "react";

const Privacy = () => {
  return (
    <>
      <div className="bg-white md:w-10/12 w-[95%] mx-auto rounded-b-lg md:mb-10 mb-2 pb-10" onLoad={window.scroll(0,0)}>
        <div className="md:w-4/5 w-11/12 mx-auto">
          <h1 className=" pt-12 md:text-3xl text-xl  font-semibold">
            What is this information?
          </h1>
          <br></br>
          <p className="  mx-auto  text-base md:text-lg">
            The information provided below describes, as required by the EU
            Regulation 2016/679, the processing operations performed on the
            personal data of the users visiting the Bicyclos website at the
            address: www.bicyclos.eu. The information provided does not concern
            other online websites, pages or services that can be accessed via
            hyperlinks. Visiting the website may result into processing data
            relating to identified or identifiable natural persons.
          </p>
        </div>
        <div className="md:w-4/5 w-11/12 mx-auto">
          <h1 className="  mt-12 md:text-3xl text-xl  mx-auto font-semibold">
            Date Controller
          </h1>
          <br></br>
          <p className=" mx-auto  text-base md:text-lg">
            The data controller is the Director of the Institute for Organic
            Syntesis and Photoreactivity, via Gobetti 101, 40129 Bologna (email:
            direzione@isof.cnr.it, PEC-certified email:
            protocollo.isof@pec.cnr.it,).
          </p>
        </div>
        <div className="md:w-4/5 w-11/12 mx-auto">
          <h1 className="  mt-12 md:text-3xl text-xl  mx-auto font-semibold">
            Data Protection Officer
          </h1>
          <br></br>
          <p className="  mx-auto  text-base md:text-lg">
            The Data Protection Officer (DPO) can be contacted at the
            email: bicyclos23@gmail.it.
          </p>
        </div>
        <div className="md:w-4/5 w-11/12 mx-auto">
          <h1 className="  mt-12 md:text-3xl text-xl  font-semibold">
            Purpose and processing of data
          </h1>
          <br></br>
          <p className="  text-base md:text-lg">
            The processing of personal data carried out through www.bicyclos.eu
            exclusively concerns navigation data normally received from the HTTP
            service (such as IP address, operating system and any other data
            normally passed from the client to the server via the HTTP
            protocol). These data are stored for one year beyond the project
            duration, then made anonymous and stored indefinitely solely for
            statistical and security purposes. The data are processed by the
            Bicyclos management team in the execution of its tasks of public
            interest, including the purposes of scientific research, raising
            public awareness and fostering public knowledge on the Bicyclos
            project, analysis for statistical purposes, pursuant to the art. 6,
            paragraph 1, letter. e) also, in order to provide and improve the
            website. These data are processed by Bicyclos staff in a lawful,
            correct, relevant manner, limited to what is necessary to achieve
            the purposes of the processing in accordance with the principles
            indicated in the art. 5 of EU Regulation 2016/679. The data are
            stored solely for any need to ascertain crimes by the judicial
            authority. Specific security measures are observed to prevent data
            loss, illicit or incorrect use and unauthorized access.
          </p>
        </div>
        <div className="md:w-4/5 w-11/12 mx-auto">
          <h1 className="  mt-12 md:text-3xl text-xl  font-semibold">
            Categories of personal data and purposes of the processing
          </h1>
          <br></br>
          <p className=" text-base md:text-lg">
          <br></br>
            <i>Browsing data</i>
            <br></br>
            <br></br>
            The information systems and software procedures relied upon to
            operate this web site acquire personal data as part of their
            standard functioning; the transmission of such data is an inherent
            feature of Internet communication protocols. This data category
            includes the IP addresses and/or the domain names of the computers
            and terminal equipment used by any user, the URI/URL (Uniform
            Resource Identifier/Locator) addresses of the requested resources,
            the time of such requests, the method used for submitting a given
            request to the server, returned file size, a numerical code relating
            to server response status (successfully performed, error, etc.), and
            other parameters related to the user&#39;s operating system and
            computer environment.
          </p>
          <h3
            id="data-list"
            className="  text-base md:text-lg"
          >
            These data are necessary to use web-based services and are also
            processed in order to:
          </h3>

          <ul aria-labelledby="data-list" className="  text-base md:text-lg my-5 list-disc">
            <li className="w-11/12 mx-auto">
              extract statistical information on service usage (most visited
              pages, visitors by time/date, geographical areas of origin, etc.);
            </li>
            <li className="w-11/12 mx-auto">check functioning of the services.</li>
          </ul>
          <p className="  mx-auto  text-base md:text-lg">
            Browsing data are kept for one year beyond the project duration
            (except where judicial authorities need such data for establishing
            the commission of criminal offences).
          </p>
          <br></br>
          <br></br>
          <i className="  text-base md:text-lg">Data communicated voluntarily by users</i>
          <br></br>
          <br></br>
          <p className="  text-base md:text-lg">
            Sending messages, on the basis of the user’s free, voluntary,
            explicit choice, to the Bicyclos contact addresses, sending private
            messages to the Bicyclos’ social media pages and profiles (where
            this option is available), and filling in and sending the forms made
            available on the Bicyclos’ website entail the acquisition of the
            sender’s contact information – which is necessary to provide a reply
            – as well as of all the personal data communicated in that manner.
          </p>
          <br></br>
          <br></br>
          <i className=" text-base md:text-lg">Cookies and other tracking devices</i>
          <br></br>
          <br></br>
          <p className="  text-base md:text-lg">
            A cookie is a small piece of text that websites send to the browser
            and are stored on the User’s terminal, which may be a personal
            computer, cell phone, tablet, etc. These files allow the website to
            monitor, personalize and save information about each User’s session,
            such as language and preferred options, which can facilitate his/her
            next visit and make the site more useful for the User. A “session”
            refers to the time the User spends on a site. Cookies play a very
            important role in improving Users’ experience on the web. No user
            profiling cookies are used. Our website uses cookies that are
            necessary for the website to function properly and ensure the
            functionality and security of the website. The only processing
            operation we perform is related to the creation of statistics via
            pseudonymised data concerning navigation on the www.bicyclos.eu
            website.
          </p>
          <h3
            id="data-list2"
            className="  text-base md:text-lg"
          >
            Based on the implemented configuration, which does not rely on
            identifying information, the following data are collected:
          </h3>

          <ul aria-labelledby="data-list2" className="  text-base md:text-lg my-5 list-disc">
            <li className="w-11/12 mx-auto">
              IP address, which is masked by setting the final 2 bytes to ‘0’
              (xxx.xxx.0.0); Operating system; Browser type; Device type (PC,
              smartphone, etc.)
            </li>
            <li className="w-11/12 mx-auto">
              IP address, which is masked by setting the final 2 bytes to ‘0’
              (xxx.xxx.0.0); Operating system; Browser type; Device type (PC,
              smartphone, etc.)
            </li>
          </ul>
          <p className="  text-base md:text-lg">
            Non-persistent session cookies are used as strictly necessary to
            allow secure, efficient navigation.
          </p>
          <br></br>
          <br></br>
          <i className="  mx-auto  text-base md:text-lg">
            Information on the processing of personal data by way of the social
            media platforms used by Bicyclos
          </i>
          <br></br>
          <br></br>
          <p className="  mx-auto  text-base md:text-lg">
            As for the processing of personal data that is carried out by the
            social media platforms used by Bicyclos, please consider the
            information provided by those platforms through their privacy
            policies. The personal data, made available by users through the
            social media pages, Bicyclos manages as part of its institutional
            purposes are processed exclusively in order to handle user
            interactions (comments, public posts, etc) in full compliance with
            the applicable legislation.
          </p>
        </div>
        <div className="md:w-4/5 w-11/12 mx-auto">
          <h1 className="  mt-12 md:text-3xl text-xl  mx-auto font-semibold">
            Rights of data subjects
          </h1>
          <br></br>
          <h3
            id="data-list3"
            className="  mx-auto  text-base md:text-lg"
          >
            Users (Data subjects) have the right to contact the Data Controller
            at any time to exercise their rights pursuant to Articles 15 to 22
            of the Regulation EU 2016/679 and, in particular to:
          </h3>

          <ul aria-labelledby="data-list3" className="  text-base md:text-lg my-5 list-disc">
            <li className="w-11/12 mx-auto">
              ask for access and confirmation of the effective existence of
              personal data regarding them;
            </li>
            <li className="w-11/12 mx-auto">
              obtain information on the purposes of the processing, the
              categories of personal data, the recipients or categories of
              recipients to whom the personal data has been or will be
              communicated and, where possible, the retention period;
            </li>
            <li className="w-11/12 mx-auto">obtain the rectification and erasure of the data;</li>
            <li className="w-11/12 mx-auto">request processing restriction;</li>
            <li className="w-11/12 mx-auto">object the processing at any time.</li>
          </ul>
          <br></br>
          <br></br>
          <p className="  text-base md:text-lg">
            Data subjects also have the right to withdraw their consent at any
            time, without prejudice to the lawfulness of the processing based on
            the consent given before the revocation.
          </p>
          <br></br>
          <p className="  text-base md:text-lg">
            For information and contact specifically related to the protection
            of personal data, including the exercise of the above rights, please
            contact the Bicyclos’ Data Protection Officer to lodge the requests
            to exercise these rights.
          </p>
          <br></br>
          <p className="  text-base md:text-lg">
            If a data subject considers that the processing of personal data
            relating to him or her as performed via this website infringes the
            Regulation EU 2016/679, he or she has the right to lodge a complaint
            with the Garante for protection of personal data pursuant to Article
            77 of the Regulation EU 2016/679, or else to bring a judicial
            proceeding against the Garante pursuant to Article 79 of the
            Regulation EU 2016/679.
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
