export const partners = [
  {
    name: "Consiglio Nazionale delle Ricerche",
    acronym:'CNR',
    type: "partners",
    image: require("../style/image/partner_image/cnr.png"),
    researcher: [
      {
        name: "Ilse Manet",
        role: "Project Coordinator",
        image: require("../style/image/researcher_profile/ilse_manet.jpeg"),
        tasks: "Dr. Ilse Manet (Coordinator) obtained her PhD in Chemical Sciences at the University of        Bologna under the supervision of Prof. Balzani and Prof. Sabatini in 1998. In 2001 she joint        CNR as permanent staff member and since 2020 she is a senior researcher at ISOF. Her        research interests cover supramolecular chemistry and the binding of small molecules to        CDs, nanoparticles, proteins and nucleic acids, as well as the photochemistry of drugs and        photoactive therapeutic agents in supramolecular complexes with CDs. She is an expert in        Fluorescence lifetime imaging of new materials and drug delivery systems in cells and        tissues. She published 100 papers in ISI journals, 4 book chapters (H-index 32, >2500        citations). She has supervised 5 PhDs and 6 postdocs mostly in the frame of FP7 and H2020        projects.",
        personalWebsite :"https://publications.cnr.it/authors/ilsegert.manet"
      },
      
    ],
    description:
      "The CNR is the largest public research institution of Italy, with ca. 8000 permanent staff members distributed in more than 100 Institutes. The scientific activity of CNR is multidisciplinary and is mainly exploited at the forefront of science and technology. The Institute for Organic Synthesis and Photoreactivity (ISOF) of the CNR employs 60 permanent staff people carrying out research in photosciences, solar energy conversion,synthesis of functional organic materials for nanotechnology, design and development ofnew drugs and new materials for nanomedicine and drug delivery systems. ISOF has a longstanding experience in participation in EC funded research projects and training of ESRsand ERs.",
    website: "https://www.cnr.it/en",
    checked:false  
  },
  {
    name: "Cell Dynamics",
    acronym:'Cell Dynamics',
    type: "partners",
    image: require("../style/image/partner_image/CellDynamics.png"),
    researcher: [
      {
        name: "Dr. Simone Pasqua",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/simone_pasqua.jpg"),
        personalWebsite :"https://www.linkedin.com/in/simone-pasqua-96194458/",
        tasks:
          "Dr. Simone Pasqua is a biologist and expert in technological transfer, with a master’s degree and a 9-years experience in commercializing new products. His expertise lies in bridging the gap between scientific innovation and market viability, adeptly navigating the journey from lab discovery to consumer-ready solutions. Simone's role involves strategic market analysis, communication of complex ideas, and leading diverse teams, ensuring each product not only meets scientific standards but also resonates with market demands.",
      }
    ],
    description:"Celldynamics (CEL) aims to set a new standard for in vitro testing in the pharmaceutical    and biotechnology sectors with solutions designed for 3D cell models helping decision    making through more reliable results. Today, our technologies are used by leading    universities around the world working with 3D cellular models to increase the reliability    of their results reducing experimental errors caused by the intrinsic heterogeneity of 3D    cell models. CEL offers laboratory tools that combine fluidics, optics, and image-based    software analysis for cellular spheroids and organoids. Our solutions are entirely    conceived, developed, produced, and marketed by us and follow a rigorous process of    evaluation and experimental verification.",
    website: "https://celldynamics.it/",
    checked:false  
  },
  {
    name: "Loránd Eötvös University",
    acronym:'ELTE',
    type: "partners",
    image: require("../style/image/partner_image/eotvos-lorand-university.png"),
    researcher: [
      {
        name: "Dr. Szabolcs Béni ",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/szabolcs beni.png"),
        personalWebsite:"https://www.linkedin.com/in/szabolcs-béni-63789620/",
        tasks:"Dr. Szabolcs Béni (PhD in Pharmaceutical Sciences) is a senior researcher, former department head of Pharmacognosy and vice dean at the Faculty of Pharmacy at Semmelweis University, currently associate professor at ELTE (Eötvös Loránd University). He is engaged in chemical research of various carbohydrates: analytical method developments for the characterization of cyclodextrins, milk oligosaccharides, and glycosaminoglycans are in the focus of his interest. NMR spectroscopy, mass spectrometry, and various separation techniques are in his toolbox to tackle the issues raised by chemists and biologists for carbohydrate characterization to advance understanding of their functions. As an analytical chemist, he is involved in method developments for the structural identification and quantitation of bio-, drug molecules and natural products. He published over 120 papers in ISI journals, (H-index 31, > 2500 independent citations). He has supervised 5, currently supervising 7 PhD students.",
      }
    ],
    description:"The predecessor of the ELTE - Eötvös Loránd University (ELTE) was founded in 1635 in    Nagyszombat (Trnava) by Cardinal Péter Pázmány. Currently, the university has nine    faculties: Economics, Education and Psychology, Humanities, Informatics, Law, Primary    and Pre-School Education, Science, Social Sciences and the Bárczi Gusztáv Faculty of    Special Needs Education. The current number of students enrolled yearly has reached    33,000, and there is an academic staff of 1,800 highly-qualified teachers and researchers.    The first Chemistry Department was founded in 1770. The Chemistry Graduate School    was established in 2000. The centralized Chemistry Institute was established in 2005.    ELTE is internationally recognized and its programs are accredited by the Hungarian    Accreditation Board. The diplomas issued by ELTE are acknowledged worldwide, and its    course credits are transferable in all countries in the European Union.",
    website: "https://www.elte.hu/en/",
    checked:false  
  },
  {
    name: "Carbohyde",
    acronym:'Carbohyde',
    type: "partners",
    image: require("../style/image/partner_image/carbohyde.jpeg"),
    researcher: [
      {
        name: "Dr. Milo Malanga",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/milo_malanga.png"),
        personalWebsite:"https://www.linkedin.com/in/milo-malanga-phd-19a846148/",
        tasks: "Dr. Milo Malanga has 13+ years of experience in carbohydrate chemistry. He has a degree in chemical and pharmaceutical technologies and focused his PhD on carbohydrate chemistry, in particular on the development of new synthetic routes towards fluorescent cyclodextrins. He has been working as senior research chemist at CycloLab for 6 years, currently working at CarboHyde as Chief Scientific Officer. He developed a large variety of cyclodextrin-based nanocarriers with targeting functions and multimodal activities. He designed  new cyclodextrins-based APIs (at-present under phase 1 clinical trial). His current interests are the design of novel cationic amphiphilic cyclodextrins able to interact with nucleic acids and to cross biological barriers. His main focus is the creation of carbohydrate-based APIs for unmet medical needs. He is author or co-author of 70 research papers, over 50 conference presentations and inventor of 7 patents. The total number of citations of his papers is over 600. ",
      }
    ],
    description:"CarboHyde, a pharma start-up developing carbohydrate-based APIs for different target    indications and supporting other companies on this field as a contract research    organization. Our mission is to drive carbohydrate-based innovations in the pharmaceutical    industry. CarboHyde develops its own carbohydrate-based APIs, focusing on rare diseases,    CNS indications and an RNA delivery platform. We continuously looking for partners to    collaborate on developing new therapeutic solutions on this field.",
    website: "https://www.carbohyde.com/",
    checked:false  
  },
  {
    name: "Hacettepe University",
    acronym:'Hacettepe University',
    type: "partners",
    image: require("../style/image/partner_image/hacettepeuni.png"),
    researcher: [
      {
        name: "Prof. Erem Bilensoy",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/Erem_bilensoy.jpg"),
        personalWebsite:'https://www.erembilensoy.com/eng/',
        tasks:"Prof. Erem Bilensoy obtained her double PhD at Université Paris-Sud and Hacettepe University in 2002. She is full professor and Head of Department at Faculty of Pharmacy, Department of  Pharmaceutical Technology HAC. Her research interests deal with the development of new drug delivery systems for application in cancer treatment. In particular she focused part of her work on (i) ampiphilic CD-based delivery systems for known antitumorals and antivirals and (ii) the development of new nanomedicines for cancer immunotherapy. She has supervised 8 PhDs and several undergraduate students at HAC. She has been awarded Honorary Member of Hellenic Society on Nanotechnology for Health Sciences in 2017 and Hungarian Society for Pharmaceutical Sciences in 2018, Doctor Honoris Causa at Carol Davila University of Medicine and Pharmacy at Bucharest . She published 95 papers in ISI journals, (H-index 31, >2900 citations). She founded Biogalenica, a research based company in 2021 and Hacettepe Technopolis. ",
      }
    ],
    description:"Hacettepe University (HAC), as one of the leading universities in our country today,    continues its contributions to social development and universal values in the fields of    science, technology and art with 16 Faculties, 15 Institutes, 4 Vocational Schools, 2    Colleges, 1 Conservatory, 98 Research and Application Centers. The mission of HAC,    as a research-oriented university, is to educate highly qualified, open to change and    development, questioning individuals in the fields of science, technology and art with    its education in the light of universal values; to provide the knowledge, services and    technology it produces for the benefit of the community. HAC Faculty of Pharmacy    was founded as Pharmacy School in 1967. In 1971, our Faculty started to serve as    independent Faculty of Pharmacy in the fields of research-development. The    pharmacy education in HAC is compliant with the Bologna initiative and several    students spend two semesters in European Universities via the ERASMUS student    exchange programme.",
    website: "https://www.hacettepe.edu.tr/english/",
    checked:false  
  },
  {
    name: "Katholieke Universiteit Leuven",
    acronym:'KU Leuven',
    type: "partners",
    image: require("../style/image/partner_image/kuLeuven.jpeg"),
    researcher: [
      {
        name: "Irene Taurino",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/irene_taurino.jpg"),
        personalWebsite:'https://www.linkedin.com/in/irene-taurino-phd-8629aa87/?originalSubdomain=be',
        tasks:"Irene Taurino obtained her Master's degree in Biomedical Engineering from Politecnico di Torino. Since 2010, she has been working in the Integrated Systems Laboratory under the supervision of Prof. Giovanni De Micheli and Prof. Sandro Carrara at the École polytechnique fédérale de Lausanne (EPFL) in Switzerland where she pursued her PhD in Microsystems and Microelectronics in September 2015. In June 2021 she became Assistant Professor at KUL in a joint position between the Electrical Engineering Department and the Department of Physics and Astronomy. Her main research focus is on groundbreaking micro-nanotechnologies and fabrication processes for physical and electrochemical platforms. She developed a strong background in nanotechnology, microfabrication, electrochemistry and microfluidics. She worked in industrial R&D in the field of aerosol science and technology and is inventor of several patent applications.",
      },
      {
        name: "Maria Antonietta Casulli",
        role: "Project Participant",
        image: require("../style/image/researcher_profile/maria_antonietta_casulli.jpg"),
        personalWebsite:'https://www.linkedin.com/in/maria-antonietta-casulli-24b77669/',
        tasks:"Maria Antonietta Casulli obtained her Master Degree (110/110) in Biomedical Engineering from Politecnico of Torino. In 2013 she won an ERASMUS fellowship, joining the Integrated Systems Laboratory at EPFL in Switzerland, developing an electrochemical sensor for the real-time monitoring of metabolites in human cell cultures. She pursued her PhD in the Laboratory of Analytical Chemistry under the supervision of prof. Takashi Hayashita at the Sophia University of Tokyo (Apr. 2019 – March 2022). Here, she developed new electrochemical assays based on chemical modified cyclodextrins for the detection of the main human metabolites (i.e. glucose, ATP). From Apr. 2022 to Feb. 2023, she carried out post-DOC research activities in the same laboratory, coordinating a project for the development of electrochemical sensors based on cyclodextrin nanogels for the detection of anti-cancer drugs (i.e. curcumin). From April 2023 she joined prof. Taurino in KU Leuven University as post-DOC researcher for coordinating projects about green electrochemical (bio)sensors.",
      }
    ],
    description:"eMATI at KUL research focuses on novel nano- and microtechnologies to boost the    performance of electrochemical systems developed for ad hoc applications. These    engineered platforms will have enhanced performance for therapeutic purposes    (high and stable molecular loading, rapid and controlled therapeutic release) and for    sensing (high selectivity, specificity and sensitivity, fast time-response, excellent    measurements reproducibility and signal stability). Considering the huge demand of    curved, stretchable and biodegradable platforms for health monitoring, another goal    of eMATI is to find new fabrication strategies to construct effectively functioning    electrochemical cells on soft substrates. eMATI designs array-based electrochemical    platforms based on novel electrodes for controlling body parts and biological    processes on chip.",
    website: "https://www.kuleuven.be/kuleuven",
    checked:false  
  },
  {
    name: "Consejo Superior De Investigaciones Cientificas",
    acronym:'CSIC',
    type: "partners",
    image: require("../style/image/partner_image/CSIC.png"),
    researcher: [
      {
        name: "Dr. José M. García Fernández",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/jose_garcia_fernandez.jpg"),
        personalWebsite:"https://twitter.com/JMGarciaFernand",
        tasks:"Dr. José M. García Fernández is Research Professor at CSIC and PI of the “Bioorganic and Supramolecular Carbohydrate Chemistry Group” at the Institute of Chemical Research (IIQ), in Seville. He has supervised 24 PhD Thesis (5 more under supervision), and is very actively involved in training of undergraduates and postdoctoral researchers as well as in international collaborations with groups in the academia and in the industry. He has co-authored about 250 scientific articles, is co-inventor of 38 patents (17 licensed), and has delivered plenary and invited lectures in International, European and National Symposia in Carbohydrate Chemistry, Cyclodextrin Chemistry, Macromolecular Chemistry and Biological Chemistry, including the International Carbohydrate Symposium or the Gordon’s Conference on Carbohydrates.",
      }
    ],
    description:"The Spanish National Research Council (CSIC) is Spain’s largest public research institution and ranks third among European Union’s largest research organisations. Attached to the Spanish Ministry of Science and Innovation, CSIC     plays a key role in scientific and technological policy in Spain and worldwide. It has    4 main missions: (i) to foster multidisciplinary scientific and technological    research, (ii) knowledge transfer to industry and society, (iii) education and    training of scientific and technical staff, (iv) creation of Technology Based    Companies (spin-offs). CSIC has more than 11 000 employees, including nearly 4    000 staff researchers, produces 20% of the national scientific output and remains the first institution in Spain in the generation of patents, with 85 patent applications in 2022.",
    website: "https://www.csic.es/en",
    checked:false  
  },
  {
    name: "MD.USE Innovative Solutions S.L",
    acronym:'MD.USE',
    type: "partners",
    image: require("../style/image/partner_image/MDUSE.png"),
    researcher: [
      {
        name: "Dr Ángel Piñeiro",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/angel_pineiro.jpg"),
        personalWebsite:"https://www.linkedin.com/in/angel-piñeiro-08896a22/",
        tasks:"Dr Ángel Piñeiro, PhD in Applied Physics, is a senior lecturer at University of Santiago de Compostela and CEO of MD.USE Innovations S.L. He is specialist in the development of computational methods to analyze intermolecular interactions: molecular recognition & aggregation-dissociation processes. This includes molecular dynamics simulations as well as the development of models for the analysis of experimental data. He has developed original software and methods including the commercial AFFINImeter software for the analysis of ITC and spectroscopy measurements. He has also published numerous research papers, book chapters, 1 patent, and supervised 2 PhDs. Publication list: https://scholar.google.com/citations?user=kSAel8YAAAAJ&hl=es",
      }
    ],
    description:
      "MD.USE Innovations S.L. is a spin-off from the University of Santiago de Compostela    (Spain) that develops software and offers services based on the study of molecules and    their interactions. Our work is mainly based on Molecular Dynamics simulations and on    the development of software for the analysis of raw data obtained from different    wet-lab methods.",
    website: "https://mduse.com/en/",
    checked:false  
  },
  {
    name: "Technological University Dublin.",
    acronym:'TU Dublin',
    type: "partners",
    image: require("../style/image/partner_image/tudublin.png"),
    researcher: [
      {
        name: "Prof Mary McNamara",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/mac__namara.jpeg"),
        personalWebsite:'https://www.tudublin.ie/research/postgraduate-research/graduate-research-school/meet-the-team/marymcnamara.html',
        tasks:"Prof Mary McNamara is Head of the Graduate Research School and a senior researcher at TU Dublin. She graduated with a PhD in Physical Inorganic Chemistry for a thesis entitled “Synthesis and Characterisation of Metallo-Cyclodextrin Complexes”.  She is a Fellow of the Royal Society of Chemistry and served two terms on the steering committee of the Council of Doctoral Education of the EUS. Professor McNamara is a member of the National Advisory Forum for Ireland’s National Framework for Doctoral Education. Prof McNamara is an active researcher in the development of novel drug delivery systems, has published >25 papers and 1 book chapter, has 2 patents and has supervised >10 PhD students.",
      }
    ],
    description:"Technological University Dublin is the largest university in Ireland and its first    technological university. With autonomous degree-awarding authority up to doctoral    level, TU Dublin combines the excellence of a traditional university with professional    career-oriented learning and prepares graduates for productive leadership roles in both    the public and the private sectors. Excellent industry engagement is reflected in    successful technology transfer, enterprise creation and a strong applied research base.    TU Dublin is organised in 5 faculties of Sciences & Health, Engineering & Built    Environment, Computing, Digital & Data, Business and Arts & Humanities and our    esearch is organised around strengths in Environment, Energy & Health, Information,    Communications & Media Technologies, New Materials & Devices and Society, Culture    and Enterprise.",
    website: "https://www.tudublin.ie/",
    checked:false  
  },
  {
    name: "Cyclarity Therapeutics",
    acronym:'Cyclarity Therapeutics',
    type: "partners",
    image: require("../style/image/partner_image/Cyclarity.webp"),
    researcher: [
      {
        name: "Dr. M. O’Connor",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/m_oconnor.jpg"),
        personalWebsite:'https://www.linkedin.com/in/droki/',
        tasks:"Dr. M. O’Connor (male, 20% FTE) PhD in Biochemistry and Molecular Biology, is CEO of Scientific Affairs and Co-Founder of Cyclarity Therapeutics. Dr. O’Connor is the former VP of Research at SENS Research Foundation and has extensive experience researching diseases of aging. His research interests include applications of cell biology, molecular biology, and biochemistry in research on aging and use of cyclodextrins to target oxidized cholesterols. He has published many peer-reviewed manuscripts and is lead inventor on the 2 published CTx cyclodextrin patents. He supervised interns, PhD students, and postdocs."
        }
    ],
    description:"Cyclarity Therapeutics (CTx) is a startup company located in California    working to develop CD APIs targeting oxidized cholesterols at the root of    common age-related diseases such as atherosclerosis. The company    currently employs 12 permanent staff, and regularly collaborates with a    variety of CD experts. Staff regularly carry out a variety of in vitro and in    vivo procedures to determine the efficacy of our novel CD therapeutic,    including cellular assays and animal studies. Additionally, computational    chemistry staff are developing a platform intended to design novel CD    drugs to target any desired hydrophobic molecule.",
    website: "https://cyclaritytx.com/",
    checked:false  
  },
  {
    name: "Buenos Aires University",
    acronym:'UBA',
    type: "partners",
    image: require("../style/image/partner_image/UBA.png"),
    researcher: [
      {
        name: "Dr. C. Modenutti",
        role: "Principal Investigator",
        image: require("../style/image/researcher_profile/c_modenutti.png"),
        personalWebsite:'https://www.linkedin.com/in/carlos-modenutti-92921062/',
        tasks:"Dr. C. Modenutti has a BSc degree in Genetics from the National University of Misiones. He worked several years near the Faculty of Pharmacy of UBA in the field of Tumoral Immunology. Now, he is PI at the Chemical Biology Department of FCEN-UBA. He has expertise in both experimental and computational approaches focusing on the glycobiology field. He has consolidated international collaborations with University of Florida, Vienna, and Barcelona. His current research focuses on bioinformatics tools for protein-carbohydrate complex structures prediction based on the analysis of solvent structure in binding sites. He published 24 papers. He has been supervising 2 PhD and 8 master students.",
      }
    ],
    description:
      "The University of Buenos Aires (UBA) is a leading institution of higher education in    Argentina. The UBA has over 300,000 students enrolled. The Faculty of Natural and Exact    Sciences at UBA is a renowned center for cutting-edge research in the fields of    mathematics, physics, chemistry, biology, and computer science. The Faculty's research    mission is to advance knowledge, promote scientific discovery, and address some of the    world's most pressing challenges. The Faculty is also committed to promoting    interdisciplinary research and collaboration, working closely with other faculties,    institutions, and industry partners to foster innovation and creativity.",
    website: "https://www.uba.ar/",
    checked:false  
  },
];
