import react, { useEffect, useRef } from "react";
import { Fragment, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  FunnelIcon,
  MinusIcon,
  PlusIcon,
  Squares2X2Icon,
} from "@heroicons/react/20/solid";
import { partners } from "../data/partner";
import { faLessThan } from "@fortawesome/free-solid-svg-icons";
import NewsView from "./news_views";
import { NavLink, Route, Routes } from "react-router-dom";
import { InView } from "react-intersection-observer";
import {
  collection,
  addDoc,
  setDoc,
  doc,
  getDocs,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { research } from "../data/researcher";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const News = () => {
  const [news, setNews] = useState([]);
  const [NotfilterNews, setNotFilterNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [updateNews, setUpdateNews] = useState({
    id: "",
    title: "",
    data: "",
    text: "",
    member: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      let list = [];

      try {
        const querySnapshot = await getDocs(collection(db, "news"));

        querySnapshot.forEach((doc) => {
          let dep = research.filter((e) => {
            if (e.name == doc.data().member) {
              return e;
            }
          });
          let img = research.filter((e) => {
            if (e.name == doc.data().member) {
              return e;
            }
          });
          dep = dep[0].department;
          img = img[0].image;
          setLoading(false);
          list.push({ department: dep, picture: img, ...doc.data() });
        });
        setNews(
          list
            .sort((a, b) => {
              let dateA = new Date(a.data);
              let dateB = new Date(b.data);
              return dateA - dateB;
            })
            .reverse()
        );
        setNotFilterNews(
          list
            .sort((a, b) => {
              let dateA = new Date(a.data);
              let dateB = new Date(b.data);
              return dateA - dateB;
            })
            .reverse()
        );
        console.log(news)
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
  function visibleNews(i) {
    var elem = document.getElementsByClassName("news-visible");
    if (!elem[i].classList.contains("active-news")) {
      elem[i].classList.toggle("active-news");
    }
  }
  function visibleFilters(i) {
    var elem = document.getElementsByClassName("filters-visible");
    if (!elem[i].classList.contains("active-filters")) {
      elem[i].classList.toggle("active-filters");
    }
  }
  function translateNews(i) {
    var elem = document.getElementsByClassName("news");
    if (!elem[i].classList.contains("news-translate")) {
      elem[i].classList.toggle("news-translate");
    }
  }
  const [sortOptions, setSortOptions] = useState([
    { name: "latest", href: "#", current: true },
    { name: "older", href: "#", current: false },
  ]);
  var collaboration = partners;
  const [dateVal, setDate] = useState(["", ""]);
  const [ordered, setOrdered] = useState("latest");
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [file, setFile] = useState("");
  const [openNews, setOpenNews] = useState(false);
  const cancelButtonRef = useRef(null);
  const [donwload, setDownload] = useState("");
  const [submit, setSubmit] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  //const [news, setNews] = useState();
  const [id, setId] = useState(null)
  const orderData = ({ target }) => {
    if (target.classList.contains("latest") && ordered != "latest") {
      setNews([...news.reverse()]);
      setOrdered("latest");
      let tmp_sort = sortOptions;
      tmp_sort[0].current = true;
      tmp_sort[1].current = false;
      setSortOptions(tmp_sort);
    }
    if (target.classList.contains("older") && ordered != "older") {
      setNews([...news.reverse()]);
      setOrdered("older");
      let tmp_sort = sortOptions;
      tmp_sort[0].current = false;
      tmp_sort[1].current = true;
      setSortOptions(tmp_sort);
    }
  };
  const dataFilter = () => {
    //controlli per i checkbox
    var checkbox = document.getElementsByName("checkbox");
    var tmp_news = [];
    var filtered = false;
    var empty = false;
    for (let i = 0; i < checkbox.length; i++) {
      collaboration[i].checked = checkbox[i].checked;
      if (checkbox[i].checked == true) {
        filtered = true;
        tmp_news = tmp_news.concat(
          NotfilterNews.filter((current) => {
            return current.department == checkbox[i].value;
          })
        );
      }
    }
    if (tmp_news.length == 0 && !filtered) {
      //se è uguale a 0 vuol dire che non ci sono checkbox selezionati
      tmp_news = NotfilterNews;
    }
    if (tmp_news.length == 0 && filtered) {
      empty = true;
    }
    if (!empty) {
      //nel caso la lista sia stata filtrata e non sia vuota
      tmp_news = tmp_news
        .sort((a, b) => {
          //ordino le news per data
          let dateA = new Date(a.data);
          let dateB = new Date(b.data);
          return dateA - dateB;
        })
        .reverse();
    }
    
    //controlli per data
    //se la lista non e vuota
    if (!empty) {
      let from = document.getElementById("from");
      let to = document.getElementById("to");
      let emptyDateA = false;
      let emptyDateB = false;
      let currentDateA;
      let currentDateB;
      if (from == null) {
        if (dateVal[0] == "") {
          emptyDateA = true;
        } else {
          currentDateA = dateVal[0];
        }
      } //situazione in cui il box date sia visibile
      else {
        if (from.value == "") {
          emptyDateA = true;
        } else {
          currentDateA = from.value;
        }
      }
      if (to == null) {
        if (dateVal[1] == "") {
          emptyDateB = true;
        } else {
          currentDateB = dateVal[1];
        }
      } else {
        //situazione in cui il box date sia visibile
        if (to.value == "") {
          emptyDateB = true;
        } else {
          currentDateB = to.value;
        }
      }
      if (emptyDateA) {
        var dateA = new Date(tmp_news[tmp_news.length - 1].data);
        setDate((prev) => ["", prev[1]]);
      } else {
        var dateA = new Date(currentDateA);
        setDate((prev) => [currentDateA, prev[1]]);
      }
      if (emptyDateB) {
        var dateB = new Date();
        setDate((prev) => [prev[0], ""]);
      } else {
        var dateB = new Date(currentDateB);
        setDate((prev) => [prev[0], currentDateB]);
      }
      tmp_news = tmp_news.filter((a) => {
        let pickedData = new Date(a.data);
        return pickedData - dateA >= 0 && pickedData - dateB <= 0;
      });
    }

    let input_box = document.getElementById("search").value;
    if (input_box != "") {
      tmp_news = tmp_news.filter((x) => {
        return x.title.toLowerCase().includes(input_box.toLowerCase());
      });
    }
    
    setNews(tmp_news);

  };
  const uploadNews = (e) => {
    setSubmitLoading(true);
    e.preventDefault();
    if (update) {
      updateAPI(e);
      return;
    } else {
      e.preventDefault();
      const storageRef = ref(storage, "news_image/" + e.target.idnews.value);
      const uploadTask = uploadBytesResumable(
        storageRef,
        e.target.file.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          alert(error.message, error.code);
          console.log(error.message, error.code);
        },
        () => {
          const downl = getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => {
              console.log("File available at", downloadURL);
              uploadData(downloadURL);
            }
          );
        }
      );
      async function uploadData(down) {
        await setDoc(doc(db, "news", e.target.idnews.value), {
          id: e.target.idnews.value,
          title: e.target.title.value,
          data: e.target.date.value,
          text: e.target.textarea.value,
          image: down,
          member: e.target.member.value,
        });
        setSubmit(true);
        setSubmitLoading(false);
        location.reload();
      }
    }
  };
  async function updateImage(downloadURL, e) {
    const newsRef = doc(db, "news", e.target.idnews.value);
    await updateDoc(newsRef, {
      image: downloadURL,
    });
  }
  async function updateAPI(e) {
    const newsRef = doc(db, "news", e.target.idnews.value);
    await updateDoc(newsRef, {
      id: e.target.idnews.value,
      title: e.target.title.value,
      data: e.target.date.value,
      text: e.target.textarea.value,
      member: e.target.member.value,
    });
    if (e.target.file.files[0] != undefined) {
      console.log("entrato immagine");
      const storageRef = ref(storage, "news_image/" + e.target.idnews.value);
      const uploadTask = uploadBytesResumable(
        storageRef,
        e.target.file.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
          }
        },
        (error) => {
          alert(error.message, error.code);
          console.log(error.message, error.code);
        },
        () => {
          const downl = getDownloadURL(uploadTask.snapshot.ref).then(
            (downloadURL) => {
              console.log("File available at", downloadURL);
              updateImage(downloadURL, e);
            }
          );
        }
      );
    }

    setSubmit(true);
    setSubmitLoading(false);
    location.reload();
  }
  const { dispatch } = useContext(AuthContext);
  const { currentUser } = useContext(AuthContext);
  async function deleteNews(e) {
    await deleteDoc(doc(db, "news", e.id));
    deleteImage(e);
  }
  function deleteImage(e) {
    let imageRef = "news_image/" + e.id;
    const desertRef = ref(storage, imageRef);
    console.log(desertRef);
    deleteObject(desertRef)
      .then(() => {
        console.log("eliminato");
        location.reload();
      })
      .catch((error) => {
        console.log("non eliminato");
      });
  }
  return (
    <>
      <Transition.Root show={openNews} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          initialFocus={cancelButtonRef}
          onClose={()=>{setOpenNews(false), setUpdate(false),setUpdateNews({
            id: "",
            title: "",
            data: "",
            text: "",
            member: "",
          })}}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <XMarkIcon
                    className="absolute h-8 w-8 end-5 top-5 cursor-pointer "
                    onClick={() => {
                      setOpenNews(false);
                      setSubmit(false);
                      setUpdate(false);
                      setUpdateNews({
                        id: "",
                        title: "",
                        data: "",
                        text: "",
                        member: "",
                      });
                    }}
                    fill="#1f2937"
                  />
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center mx-auto sm:mt-0 sm:text-left w-4/5">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          {update ? (
                            <div>update News</div>
                          ) : (
                            <div>upload News</div>
                          )}
                        </Dialog.Title>
                        <div className="mt-2">
                          <form onSubmit={uploadNews}>
                            <div className="relative z-0 w-full mb-5 group">
                              <input
                                type="text"
                                name="idnews"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-[#80968A] peer"
                                placeholder=" "
                                disabled
                                defaultValue={id}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                ID
                              </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                              <input
                                type="text"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-[#80968A] peer"
                                placeholder=" "
                                name="title"
                                defaultValue={updateNews.title}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A]  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                title
                              </label>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                              <input
                                type="text"
                                name="date"
                                id="floating_repeat_password"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-[#80968A] peer"
                                placeholder=" "
                                defaultValue={updateNews.data}
                              />
                              <label className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-[#80968A] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">
                                aaaa-mm-gg
                              </label>
                            </div>

                            <div className="relative z-0 w-full mb-5 group">
                              <select id="member" name="member">
                                {research.map((e, i) => {
                                  return (
                                    <option value={e.name} key={i} selected={e.name==updateNews.member}>
                                      {e.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="relative z-0 w-full mb-5 group">
                              <textarea
                                id="about"
                                name="textarea"
                                placeholder="insert news text here"
                                rows={5}
                                className=" w-full rounded-md border-0 py-1.5 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#80968A] sm:md:text-lg text-base sm:leading-6"
                                defaultValue={updateNews.text}
                              />
                            </div>
                            <label className="block mb-2 text-sm font-medium text-gray-900 ">
                              Upload file
                            </label>
                            <input
                              className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none "
                              type="file"
                              name="file"
                            />

                            {submitLoading ? (
                              <div
                                role="status"
                                className="flex flex-row border-[#80968A] border-2 rounded-lg p-2"
                              >
                                <svg
                                  aria-hidden="true"
                                  className="w-8 h-8 text-gray-200 animate-spin  fill-[#80968A]"
                                  viewBox="0 0 100 101"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"
                                  />
                                  <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"
                                  />
                                </svg>
                                <p className="md:text-lg text-base ml-2">
                                  uploading news...
                                </p>
                              </div>
                            ) : (
                              <button
                                type="submit"
                                className="mt-5 bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A] md:h-1/2 md:text-lg  text-white font-bold py-1 px-4 rounded-lg l-0 mb-5"
                              >
                                Upload
                              </button>
                            )}
                            {submit && (
                              <div
                                className="bg-green-100 border-t border-b border-green-800 text-green-700 px-4 py-3 md:mb-14 mt-5"
                                role="alert"
                              >
                                <p className="md:text-lg text-base ml-2">
                                  news uploaded successfully
                                </p>
                              </div>
                            )}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div
        className="  min-h-[70vh] bg-white w-11/12 mx-auto mb-10 rounded-b-lg"
        onLoad={() => {
          window.scroll(0, 0);
        }}
      >
        <div className="">
          {/* Mobile filter dialog */}
          <Transition.Root show={mobileFiltersOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setMobileFiltersOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                    <div className="flex items-center justify-between px-4">
                      <button
                        type="button"
                        className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-800"
                        onClick={() => setMobileFiltersOpen(false)}
                      >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {/* Filters mobile */}
                    <form className="mt-4 border-t border-gray-200">
                      <h3 className="sr-only">Categories</h3>
                      <Disclosure
                        as="div"
                        className="border-b border-gray-200 py-6 px-4"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-base text-gray-800 hover:text-gray-500">
                                <span className=" text-gray-900">Date</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-x-2 flex flex-row justify-between">
                                <div className=" mx-auto ">
                                  <label className="block mb-2 text-base  text-gray-800 ">
                                    From:
                                  </label>
                                  <input
                                    type="date"
                                    id="from"
                                    onChange={dataFilter}
                                    defaultValue={dateVal[0]}
                                    aria-describedby="helper-text-explanation"
                                    className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-lg  block w-full p-2.5 "
                                    placeholder="90210"
                                  />
                                </div>
                                <div className=" ">
                                  <label className="block mb-2 text-base  text-gray-900 ">
                                    To:
                                  </label>
                                  <input
                                    type="date"
                                    id="to"
                                    onChange={dataFilter}
                                    defaultValue={dateVal[1]}
                                    aria-describedby="helper-text-explanation"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5  "
                                    placeholder="90210"
                                  />
                                </div>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure
                        as="div"
                        className="border-b border-gray-200 px-4 py-6"
                      >
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-800 hover:text-gray-500">
                                <span className=" text-gray-800">Partners</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <PlusIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {collaboration.map((option, i) => {
                                  return (
                                    <>
                                      <div
                                        key={option.name}
                                        className="flex items-center"
                                      >
                                        <input
                                          id={`filter-mobile-${option.name}`}
                                          defaultValue={option.name}
                                          type="checkbox"
                                          name="checkbox"
                                          onChange={dataFilter}
                                          defaultChecked={option.checked}
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={`filter-mobile-${option.name}`}
                                          className="ml-3 min-w-0 flex-1 text-gray-500"
                                        >
                                          {option.name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </Dialog>
          </Transition.Root>

          <main className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <InView
              as="div"
              onChange={(inView, entry) => {
                if (inView) {
                  setTimeout(visibleNews(0), 4000);
                }
              }}
              className="news-visible opacity-0 flex flex-row mx-auto items-baseline flex-wrap md:flex-nowrap justify-center md:justify-between border-b border-gray-200 pb-10 pt-10"
            >
              <h1 className="text-5xl font-bold my-auto flex-none md:flex-auto w-full md:w-fit text-center md:text-left tracking-tight text-[#80968A] mb-5 md:mb-0">
                News
              </h1>
              <div className="relative left-0">
                <svg
                  className="w-6 h-6 text-gray-700 absolute top-3 left-0"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
                <input
                  type="search"
                  placeholder="Search"
                  id="search"
                  onChange={dataFilter}
                  className="border-b w-full text-lg border-gray-200 py-3 pl-7 bg-transparent"
                />
              </div>
              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="group inline-flex justify-center text-lg text-gray-800 hover:text-gray-900">
                      Sort
                      <ChevronDownIcon
                        className="m-auto h-5 w-5 flex-shrink-0 "
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        {sortOptions.map((option) => (
                          <Menu.Item key={option.name}>
                            {({ active }) => (
                              <a
                                role={option.name}
                                value={option.name}
                                onClick={orderData}
                                className={classNames(
                                  option.current
                                    ? ` text-gray-800 ${option.name}`
                                    : `text-gray-500 ${option.name}`,
                                  active
                                    ? `bg-gray-100 ${option.name}`
                                    : option.name,
                                  `block px-4 py-2 text-lg md:text-base ${option.name}`
                                )}
                              >
                                {option.name}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <button
                  type="button"
                  className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  <span className="sr-only">Filters</span>
                  <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </InView>

            <section aria-labelledby="products-heading" className="pb-24 pt-6">
              <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4">
                {/* Filters */}
                <InView
                  as="div"
                  onChange={(inView, entry) => {
                    if (inView) {
                      setTimeout(visibleFilters(0), 4000);
                    }
                  }}
                  className="filters-visible hidden lg:block opacity-0"
                >
                  <Disclosure
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between  py-3 text-lg  hover:text-gray-500">
                            <span className="font-medium text-gray-900">
                              Date
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-x-2 flex flex-row justify-between">
                            <div className=" mx-auto ">
                              <label className="block mb-2 text-lg text-gray-800 ">
                                From:
                              </label>
                              <input
                                type="date"
                                id="from"
                                onChange={dataFilter}
                                defaultValue={dateVal[0]}
                                aria-describedby="helper-text-explanation"
                                className="bg-gray-50 border border-gray-300 text-gray-800 text-base rounded-lg  block w-full p-2.5 "
                                placeholder="90210"
                              />
                            </div>
                            <div className=" ">
                              <label className="block mb-2 text-lg  text-gray-800 ">
                                To:
                              </label>
                              <input
                                type="date"
                                id="to"
                                onChange={dataFilter}
                                defaultValue={dateVal[1]}
                                aria-describedby="helper-text-explanation"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg  block w-full p-2.5 "
                                placeholder="90210"
                              />
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure
                    as="div"
                    className="border-b border-gray-200 py-6"
                  >
                    {({ open }) => (
                      <>
                        <h3 className="-mx-2 -my-3 flow-root">
                          <Disclosure.Button className="flex w-full items-center justify-between  px-2 py-3 text-gray-800 hover:text-gray-500">
                            <span className=" text-lg text-gray-800">
                              Partners
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel className="pt-6">
                          <div className="space-y-6">
                            {collaboration.map((option, i) => (
                              <div
                                key={option.name}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-mobile-${option.name}`}
                                  name="checkbox"
                                  defaultValue={option.name}
                                  type="checkbox"
                                  onChange={dataFilter}
                                  defaultChecked={option.checked}
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-mobile-${option.name}`}
                                  className="ml-3 min-w-0 flex-1 text-gray-800 text-lg"
                                >
                                  {option.name}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </InView>

                {/* Product grid */}
                <div className="lg:col-span-3">
                  <div className="md:w-4/6 w-5/6 m-auto  my-10">
                  {loading && <div className="wrapper_load">
                        <div className="circle_load"></div>
                        <div className="circle_load"></div>
                        <div className="circle_load"></div>
                        <div className="shadow_load"></div>
                        <div className="shadow_load"></div>
                        <div className="shadow_load"></div>
                      <span>Loading</span>
                    </div>}
                   
                    {currentUser != null && !loading && (
                      <button
                        className=" bg-[#80968A] hover:bg-[#C5D4D0] hover:text-[#80968A]  md:text-lg  text-white font-bold py-1 px-4 mx-auto rounded-lg l-0 mb-5"
                        onClick={() => {
                          setOpenNews(true);
                          setId(NotfilterNews.length+1)
                        }}
                      >
                        {" "}
                        + Add News
                      </button>
                    )}
                    

                    {news.length != 0 && !loading && (
                      <ol className="relative mt-3 border-s-2 border-[#80968A]  border-opacity-60">
                        {news.map((e, i) => {
                          var date = new Date(e.data);
                          const month = [
                            "January",
                            "February",
                            "March",
                            "April",
                            "May",
                            "June",
                            "July",
                            "August",
                            "September",
                            "October",
                            "November",
                            "December",
                          ];

                          return (
                            <li className="my-10 ms-6 h-auto" key={i}>
                              <span className="absolute flex items-center justify-center w-8 h-8 bg-[#C5D4D0] rounded-full -start-4">
                                <svg
                                  className="w-4 h-4 text-[#68736B] "
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                </svg>
                              </span>
                              <InView
                                as="div"
                                onChange={(inView, entry) => {
                                  if (inView) {
                                    setTimeout(translateNews(i), 4000);
                                  }
                                }}
                                className="news opacity-0"
                              >
                                <h3 className="flex items-center mb-1 md:text-2xl text-xl font-semibold text-gray-900 ">
                                  {e.title}
                                  {currentUser != null && (
                                    <>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="-2 -2 28 28"
                                        strokeWidth="1.5"
                                        stroke="white"
                                        className="w-6 h-6 rounded-full bg-green-600 ml-3 cursor-pointer"
                                        onClick={() => {
                                          setUpdate(true);
                                          setOpenNews(true);
                                          setId(e.id)
                                          setUpdateNews(e);
                                        }}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                        />
                                      </svg>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="-2 -2 28 28"
                                        strokeWidth="1.5"
                                        stroke="white"
                                        className="w-6 h-6 rounded-full bg-red-700 ml-1 cursor-pointer"
                                        onClick={() => {
                                          deleteNews(e);
                                        }}
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                        />
                                      </svg>
                                    </>
                                  )}

                                  {i == 0 && ordered == "latest" && (
                                    <span className="bg-[#DC8855] text-white  text-base font-medium me-2 px-2.5 py-0.5 rounded ms-3">
                                      Latest
                                    </span>
                                  )}
                                  {i == 0 && ordered == "older" && (
                                    <span className="bg-[#DC8855] text-white  text-base font-medium me-2 px-2.5 py-0.5 rounded  ms-3">
                                      Oldest
                                    </span>
                                  )}
                                </h3>
                                <time className="block my-4 text-base font-normal leading-none text-gray-400 ">
                                  published on{" "}
                                  {date.getDate() +
                                    " " +
                                    month[date.getMonth()] +
                                    " " +
                                    date.getFullYear()}
                                </time>
                                <p className="mb-1  md:text-lg text-base font-normal line-clamp-3   text-gray-500 ">
                                  {e.text}
                                </p>
                                <NavLink
                                  to={`/news_view/${e.id}`}
                                  state={{ news: e }}
                                  className="inline-flex mt-3 items-center px-2 py-1 md:text-lg text-base font-medium hover:bg-[#C5D4D0] hover:text-[#80968A] bg-[#80968A] text-white  rounded-lg"
                                >
                                  Learn more{" "}
                                  <svg
                                    className="w-3 h-3 ms-2 rtl:rotate-180"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 14 10"
                                  >
                                    <path
                                      stroke="currentColor"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth="2"
                                      d="M1 5h12m0 0L9 1m4 4L9 9"
                                    />
                                  </svg>
                                </NavLink>
                              </InView>
                            </li>
                          );
                        })}
                      </ol>
                    )}
                    {news.length == 0 && !loading && (
                      <div
                        className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4"
                        role="alert"
                      >
                        <p className="font-bold">We are sorry!</p>
                        <p>No news found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
};

export default News;
