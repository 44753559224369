import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: "bicyclos2023.firebaseapp.com",
  projectId: "bicyclos2023",
  storageBucket: "bicyclos2023.appspot.com",
  messagingSenderId: "489190184654",
  appId: "1:489190184654:web:4d9d7c55ac40d07bfd629f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
export const auth = getAuth()
export const storage = getStorage(app)
